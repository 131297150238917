// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n/*******************\n * JAVASCRIPT EXPORT\n *******************/\n\n/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.ProductHeader {\n  background: #ffffff;\n  height: 75px;\n  max-height: 75px;\n  border-bottom: 1px solid #f5f9fc; }\n  .ProductHeader__content {\n    margin: 0.4rem 0.8rem; }\n  .ProductHeader__icon {\n    margin-right: 0.6rem;\n    width: 75px;\n    height: 75px;\n    max-width: 75px;\n    max-height: 75px; }\n    .ProductHeader__icon > img {\n      max-width: 75px;\n      max-height: 75px; }\n", ""]);
// Exports
exports.locals = {
	"primary": "#0e3f60",
	"secondary": "#32d69a",
	"danger": "#EA1818",
	"chartLineColor1": "#32d69a",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"portfolioDefaultColor": "#959595",
	"portfolioSharesColor": "#32d69a",
	"portfolioBondsColor": "#0e3f60",
	"portfolioCashColor": "#757b7f",
	"portfolioOtherColor": "#3c3b3b",
	"defaultFontSize": "16px",
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"green": "#32d69a",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

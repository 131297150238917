import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import FastClick from 'fastclick'

import MessageService from 'services/api/message'
import { logout } from 'core/api'
import { logoutUser } from 'actions/user'

import { Dashboard, Service, Postbox, Account, PDFPage, Product, Page, Message, Deposit } from './'
import { ProductDetailsGermany } from './ProductDetailsGermany'
import HeaderBar from 'components/UI/HeaderBar'
import Modal from 'components/UI/Modal'
import defaultResource from 'resources/default.json'

import '../../stylesheets/utils/helpers.scss'
import variables from '../../stylesheets/utils/variables.scss'

const muiTheme = createMuiTheme({
  palette: {
    primary1Color: variables.primaryColor,
    accent1Color: variables.secondaryColor,
  },
})

function InsideArea() {
  const history = useHistory()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.user.data)
  const products = useSelector((state) => state.user.products)

  const [postBoxNew, setPostBoxNew] = useState(0)
  const [postInterval, setPostInterval] = useState(null)
  const [showIndex, setShowIndex] = useState(null)
  const [showInfo, setShowInfoState] = useState({})

  const hasUnmatchedProduct = products.some((product) => product.typ === 'NUTZER' && !userData.matched)

  function setShowInfo(id: string, show: boolean): void {
    setShowInfoState((prevState) => ({ ...prevState, [id]: show }))
  }

  function onLogout(): void {
    logout(() => {
      dispatch(logoutUser())
      history.push('/logout')
    })
  }

  async function getMessages() {
    // get messages
    const response = await MessageService.list()
    const { messages } = response.data

    setPostBoxNew(messages.filter((el) => !el.readdate).length)

    const postInterval = setInterval(async () => {
      const response = await MessageService.list()
      const { messages } = response.data

      setPostBoxNew(messages.filter((el) => !el.readdate).length)
    }, 60000)

    setPostInterval(postInterval)
  }

  useEffect(() => {
    // const arr = []
    // for (let i in defaultResource.inside[this.props.match.params.tab]) {
    //   if (i !== 'hasHeader') {
    //     arr.push(false)
    //   }
    // }
    // this.setState({ showInfo: arr })

    FastClick.attach(document.body)

    if (window.StatusBar) {
      StatusBar.backgroundColorByHexString(variables.backgroundColor)
    }

    getMessages()

    return () => clearInterval(postInterval)
  }, [])

  const props = {
    resources: defaultResource.inside,
    setPostBoxNew: setPostBoxNew,
    setShowInfo: setShowInfo,
    setShowIndex: setShowIndex,
    showIndex: showIndex,
    showInfo: showInfo,
  }

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className="InsideArea view">
        <HeaderBar messagesUnreadCount={postBoxNew} />

        <div className="content">
          <Switch>
            {/* User */}
            <Route path="/inside" exact render={() => <Dashboard {...props} />} />
            <Route path="/inside/service" exact render={() => <Service {...props} />} />
            <Route path="/inside/service/*" render={() => <Service {...props} />} />
            <Route path="/inside/postbox" render={() => <Postbox {...props} />} />
            <Route path="/inside/message/:id" render={() => <Message {...props} />} />
            <Route path="/inside/attachment/:subject/:type/:pdfURL" render={() => <PDFPage {...props} />} />
            <Route path="/inside/page/:title/:url" render={() => <Page {...props} />} />
            <Route path="/inside/konto" exact render={() => <Account {...props} />} />
            <Route path="/inside/konto/*" render={() => <Account {...props} />} />

            {/* Product */}
            <Route path="/inside/:index/details" exact render={() => <Account {...props} />} />
            <Route path="/inside/:index/details/*" render={() => <Account {...props} />} />
            <Route path="/inside/:index/deposit" render={() => <Deposit {...props} />} />
            <Route path="/inside/de/:index/:tab" exact render={() => <ProductDetailsGermany {...props} />} />
            <Route path="/inside/:index/:tab" exact render={() => <Product {...props} />} />
            <Route path="/inside/:index/:tab/*" render={() => <Product {...props} />} />
          </Switch>
        </div>
      </div>

      <Modal className="InsideArea__lockedModal" contentLabel="Info" isOpen={hasUnmatchedProduct} hideCloseButton>
        <div className="InsideArea__lockedModalButton" onClick={onLogout}>
          Abmelden
        </div>
        <div dangerouslySetInnerHTML={{ __html: defaultResource.inside.modals.unmatched }} />
      </Modal>
    </MuiThemeProvider>
  )
}

export default InsideArea

/* @flow */
'use strict'

import React, { Component } from 'react'
import ReactHighcharts from 'react-highcharts'
require('highcharts-data')(ReactHighcharts.Highcharts)
require('highcharts-more')(ReactHighcharts.Highcharts)

import { formatValue } from 'core/helpers'
import HIGHCHARTS_CONFIG from 'core/highcharts'
import variables from 'stylesheets/utils/variables.scss'

type Props = {
  columns: Array<any>,
  currencySign: string,
  currentValue: number,
  enableAnimation?: boolean,
  fontFamily?: string,
  disableRedraw?: boolean,
  height?: number,
  autoHeight?: boolean,
  isMobile: boolean,
  modalClassName?: string,
  oppositeYLabels?: boolean,
  rows: Array<any>,
  showFinabroModel: boolean,
  showFirstPoint?: boolean,
  targetAmount?: ?number,
  xAxisFirstLabel: ?string,
  xAxisLastLabel: ?string,
  disableMouseTracking: ?boolean,
  startOnTick?: boolean,
  endOnTick?: boolean,
  xAxisGrid: ?boolean,
  yAxisGrid: ?boolean,
  labelsYEnabled: ?boolean,
  showValueInLegend: ?boolean,
  yAxisLabelsInsideGrid: ?boolean,
  customYTicks: ?boolean,
  //isBAVProduct: ?boolean,
  resources?: Object,
}

type State = {
  dataMinX: number,
  dataMaxX: number,
  dataMaxY: number,
  dataMaxYLineDurchschnitt: number,
  dataMinYLineDurchschnitt: number,
  dataMaxYLineVorteilhaft: number,
  showEinbezahltModal: boolean,
  showDurchschnittlichModal: boolean,
  showSchlechtModal: boolean,
  showOptimistischModal: boolean,
  legendValues: Array<number>,
}

class ForecastChart extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      dataMinX: 0,
      dataMaxX: 0,
      dataMaxY: 0,
      dataMaxYLineDurchschnitt: 0,
      dataMinYLineDurchschnitt: 0,
      dataMaxYLineVorteilhaft: 0,
      legendValues: [],
    }

    this.addDataLabels = this.addDataLabels.bind(this)
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (this.props.disableRedraw) {
      if (nextState !== this.state) {
        return true
      } else if (nextProps.rows !== this.props.rows) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  componentDidMount() {
    const chart = this.refs.chart.getChart()
    const rows = this.props.rows
    this.setState({
      ...this.state,
      dataMinX: rows[0][0],
      dataMaxX: rows[rows.length - 1][0],
      dataMaxY: chart.yAxis[0].dataMax,
    })
  }

  render() {
    const {
      columns,
      currencySign,
      enableAnimation,
      fontFamily,
      height,
      autoHeight,
      isMobile,
      oppositeYLabels,
      rows,
      showFinabroModel,
      showFirstPoint,
      targetAmount,
      xAxisFirstLabel,
      xAxisLastLabel,
      disableMouseTracking,
      startOnTick,
      endOnTick,
      xAxisGrid,
      yAxisGrid,
      labelsYEnabled,
      showValueInLegend,
      yAxisLabelsInsideGrid,
      customYTicks,
      produktmantel,
      setShowEndDateModal,
    } = this.props

    const {
      dataMinX,
      dataMaxX,
      dataMaxY,
      dataMaxYLineDurchschnitt,
      dataMinYLineDurchschnitt,
      dataMaxYLineVorteilhaft,
      legendValues,
    } = this.state

    let initialMouseOver = true
    // show line for targetAmount only if targetAmount is within
    // general max limit of diagram ( see parameter max in series ) AND
    // max value of line "Vorteilhafte Marktentwicklung" AND
    // min of line "Durchschnittliche Marktentwicklung"
    let showLineTargetAmount = false
    if (
      targetAmount &&
      targetAmount <= dataMaxYLineDurchschnitt + dataMaxYLineDurchschnitt * 0.26 &&
      targetAmount <= dataMaxYLineVorteilhaft &&
      targetAmount >= dataMinYLineDurchschnitt
    ) {
      showLineTargetAmount = true
    }

    // prepare line names

    // clone columns array and add 'null' field at the beginning
    // because the first value of rows is always the timestamp
    const names = columns.slice()
    names.unshift(null)

    let data
    let series
    let minYSchlecht = Number.MAX_VALUE
    let minYEinbezahlt = Number.MAX_VALUE
    if (!showFinabroModel) {
      // clone rows array and add names at the beginning for using as line names
      const scenarioData = rows.slice()
      scenarioData.unshift(names)

      data = { rows: scenarioData }
      const lastValIndex = scenarioData.length - 1

      const indexDurchschnitt = names.indexOf('Durchschnittliche Marktentwicklung')
      const indexSchlecht = names.indexOf('Schlechte Marktentwicklung')
      const indexEinbezahlt = names.indexOf('Einbezahltes Kapital')

      legendValues.push(
        scenarioData[lastValIndex][indexDurchschnitt],
        scenarioData[lastValIndex][indexReduziert],
        scenarioData[lastValIndex][indexEinbezahlt],
      )
      minYSchlecht = scenarioData[lastValIndex][indexSchlecht]
      minYEinbezahlt = scenarioData[lastValIndex][indexEinbezahlt]
    } else {
      // get indizes of line names
      const indexTimestamp = 0
      const indexVorteilhaft = names.indexOf('Vorteilhaft Marktentwicklung')
      const indexDurchschnitt = names.indexOf('Durchschnittliche Marktentwicklung')
      const indexReduziert = names.indexOf('Reduzierte Marktentwicklung')
      const indexSchlecht = names.indexOf('Schlechte Marktentwicklung')
      const indexEinbezahlt = names.indexOf('Einbezahltes Kapital')

      // prepare data
      const areaVorteilhaft = []
      const areaSchlecht = []
      const lineDurchschnitt = []
      const lineSchlecht = []
      const lineEinbezahlt = []

      for (let i = 0; i < rows.length; i++) {
        areaVorteilhaft.push([rows[i][indexTimestamp], rows[i][indexVorteilhaft], rows[i][indexReduziert]])
        areaSchlecht.push([rows[i][indexTimestamp], rows[i][indexReduziert], rows[i][indexSchlecht]])
        lineDurchschnitt.push([rows[i][indexTimestamp], rows[i][indexDurchschnitt]])
        lineSchlecht.push([rows[i][indexTimestamp], rows[i][indexSchlecht]])
        minYSchlecht = minYSchlecht > rows[i][indexSchlecht] ? rows[i][indexSchlecht] : minYSchlecht
        lineEinbezahlt.push([rows[i][indexTimestamp], rows[i][indexEinbezahlt]])
        minYEinbezahlt = minYEinbezahlt > rows[i][indexEinbezahlt] ? rows[i][indexEinbezahlt] : minYEinbezahlt
      }

      const lastVal = rows[rows.length - 1]
      legendValues.push(lastVal[indexDurchschnitt], lastVal[indexSchlecht], lastVal[indexEinbezahlt])

      if (produktmantel === 3) {
        series = [
          {
            data: areaVorteilhaft,
            fillColor: {
              linearGradient: !autoHeight ? [0, 0, 0, height] : [0, 0, 0, 1],
              stops: [
                [0, '#e6f5f7'],
                [1, '#fff'],
              ],
            },
            showInLegend: false,
            type: 'areasplinerange',
          },
          {
            color: variables.secondaryColor,
            data: lineDurchschnitt,
            name: 'Angenommene Wertsteigerung',
            type: 'spline',
            zIndex: 2,
          },
          {
            color: variables.chartLineColor,
            data: lineEinbezahlt,
            name: 'Einbezahlte Nettoprämie',
            type: 'spline',
            zIndex: 1,
          },
        ]
      } else {
        series = [
          {
            data: areaVorteilhaft,
            fillColor: {
              linearGradient: !autoHeight ? [0, 0, 0, height] : [0, 0, 0, 1],
              stops: [
                [0, '#e6f5f7'],
                [1, '#fff'],
              ],
            },
            showInLegend: false,
            type: 'areasplinerange',
          },
          {
            data: areaSchlecht,
            fillColor: {
              linearGradient: !autoHeight ? [0, 0, 0, height] : [0, 0, 0, 1],
              stops: [
                [0, '#fdebe1'],
                [1, '#fff'],
              ],
            },
            showInLegend: false,
            type: 'areasplinerange',
          },
          {
            color: variables.secondaryColor,
            data: lineDurchschnitt,
            name: 'Durchschnittliche Marktentwicklung',
            type: 'spline',
            zIndex: 3,
          },
          {
            color: variables.chartLineColor,
            data: lineEinbezahlt,
            name: 'Einbezahltes Kapital',
            type: 'spline',
            zIndex: 2,
          },
          {
            color: variables.chartLineColor2,
            data: lineSchlecht,
            name: 'Schlechte Marktentwicklung',
            type: 'spline',
            zIndex: 1,
          },
        ]
      }
    }

    const highChartsConfig = {
      ...HIGHCHARTS_CONFIG.basic,
      chart: {
        ...HIGHCHARTS_CONFIG.chart,
        style: {
          ...HIGHCHARTS_CONFIG.chart.style,
          fontFamily: fontFamily ? fontFamily : HIGHCHARTS_CONFIG.chart.style.fontFamily,
        },
        height: !autoHeight ? height : null,
        marginRight:
          endOnTick || !showValueInLegend
            ? 0
            : dataMaxY >= 10000000
            ? 85
            : dataMaxY >= 1000000
            ? 75
            : dataMaxY >= 100000
            ? 65
            : 57,
        type: 'spline',
      },
      colors: !showFinabroModel ? ['#0E3F60', '#468FBF', '#EA1818', '#959595'] : null,
      data: !showFinabroModel ? data : null,
      legend: {
        align: 'left',
        enabled: !isMobile,
        floating: true,
        itemStyle: {
          fontFamily: fontFamily ? fontFamily : 'OpenSansRegular',
          fontSize: 11,
          fontWeight: 400,
          color: '#515355',
        },
        itemHoverStyle: {
          cursor: 'default !important',
          color: '#515355',
        },
        layout: 'vertical',
        symbolWidth: 8,
        verticalAlign: 'top',
      },
      plotOptions: {
        areasplinerange: {
          animation: !!enableAnimation,
          enableMouseTracking: false,
          lineWidth: 0,
        },
        spline: {
          enableMouseTracking: !disableMouseTracking,
          animation: !!enableAnimation,
          dataLabels: {
            crop: false,
            formatter: function () {
              // Bug workaround
              if (
                (!!autoHeight && this.point === this.series.data[this.series.data.length - 1]) ||
                this.point.options.showLabel
              ) {
                return `<span style="color: ${this.point.color}">${currencySign}${formatValue(this.y)}</span>`
              }
              return null
            },
            style: {
              fontFamily: 'OpenSansRegular',
            },
            overflow: 'none',
            x: 26,
            y: 9,
          },
          events: {
            // make tooltip and marker at first point visible after initial animation
            // for line 'Durchschnittliche Marktentwicklung' ( index = 2 in this.chart.series )
            afterAnimate: function () {
              if (this.index === 2 && showFirstPoint !== false) {
                const firstPoint = this.points[0]
                firstPoint.setState('hover')
                this.chart.tooltip.refresh(firstPoint)
              }
            },
            // disable hiding line when clicking on legend item
            legendItemClick: function () {
              return false
            },
            mouseOver: function () {
              // hide marker at first point again on initial mouse over on chart
              // this is necessary because otherwise marker only disappears when hovering respetive line
              if (initialMouseOver) {
                const series = this.chart.series
                const firstPoint = series[2].points[0]
                firstPoint.setState()
                initialMouseOver = false
              }
            },
          },
          lineWidth: 1,
          marker: HIGHCHARTS_CONFIG.marker,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
      },
      series: showFinabroModel ? series : [],
      tooltip: {
        ...HIGHCHARTS_CONFIG.tooltip,
        formatter: function () {
          return `${currencySign}${formatValue(this.y)}`
        },
      },
      xAxis: {
        ...HIGHCHARTS_CONFIG.xAxis,
        gridLineWidth: !!xAxisGrid ? 1 : 0,
        gridLineColor: '#f6f6f6',
        startOnTick: !!startOnTick,
        endOnTick: !!endOnTick,
        labels: {
          enabled: false,
        },
        tickPositioner: !!xAxisGrid
          ? function () {
              let step = (dataMaxX - dataMinX) / 4
              return [dataMinX, dataMinX + step, dataMinX + step * 2, dataMinX + step * 3, dataMaxX]
            }
          : null,
      },
      yAxis: {
        ...HIGHCHARTS_CONFIG.yAxis,
        gridLineWidth: !!yAxisGrid ? 1 : 0,
        gridLineColor: '#f6f6f6',
        lineWidth: 0,
        minPadding: 0.03,
        // NOTE: add max value limit for yAxis depending on max value of line
        // "Durschnittliche Marktentwicklung" because values of blue area
        // ("Vorteilhafte Marktentwicklung") could be much too high to show in chart
        opposite: oppositeYLabels ? oppositeYLabels : false,
        plotLines: !!targetAmount
          ? [
              {
                color: '#959595',
                dashStyle: 'dash',
                label: {
                  style: { fontSize: 15 },
                  text: `Ihr Ziel: €${formatValue(targetAmount)}`,
                },
                value: showLineTargetAmount ? targetAmount : null,
                width: 1,
                zIndex: 99,
              },
            ]
          : null,
        labels: !!labelsYEnabled
          ? {
              enabled: true,
              formatter: function () {
                return `${currencySign}${formatValue(this.value)}`
              },
              reserveSpace: yAxisLabelsInsideGrid ? false : null,
              align: yAxisLabelsInsideGrid ? 'right' : 'left',
              x: yAxisLabelsInsideGrid ? 0 : 10,
              y: yAxisLabelsInsideGrid ? -5 : undefined,
            }
          : { enabled: false },
        /*tickPositioner: isBAVProduct && (function(min, max) {
          let positions = this.tickPositions
          positions[0] = min
          return positions
        }),*/
        min: /*isBAVProduct ? minYEinbezahlt :*/ 0,
        startOnTick: true,
      },
    }

    return (
      <div className="ForecastChart">
        <ReactHighcharts ref="chart" config={highChartsConfig}></ReactHighcharts>

        <div className="ForecastChart__labels">
          <div className="xAxis-fixed-label">{xAxisFirstLabel}</div>
          <div className="ForecastChart__endDateToggle" onClick={() => setShowEndDateModal(true)}>
            Zeitraum auswählen
          </div>
          <div className="xAxis-fixed-label right">{xAxisLastLabel}</div>
        </div>
      </div>
    )
  }

  // enable dataLabel for last point in each serie
  addDataLabels(chart: Object): void {
    const autoHeight = this.props.autoHeight
    const { dataMaxYLineDurchschnitt, dataMinYLineDurchschnitt, dataMaxYLineVorteilhaft } = this.state
    const newDataMaxYLineDurchschnitt = chart.yAxis[0].series[2].dataMax
    const newDataMinYLineDurchschnitt = chart.yAxis[0].series[2].dataMin
    const newDataMaxYLineVorteilhaft = chart.yAxis[0].series[0].dataMax

    if (!autoHeight) {
      chart.series.map((serie) => {
        const points = serie.points
        points[points.length - 1].options.showLabel = true
      })
    }

    if (dataMaxYLineDurchschnitt !== newDataMaxYLineDurchschnitt) {
      this.setState({
        ...this.state,
        dataMaxYLineDurchschnitt: newDataMaxYLineDurchschnitt,
      })
    }

    if (dataMinYLineDurchschnitt !== newDataMinYLineDurchschnitt) {
      this.setState({
        ...this.state,
        dataMinYLineDurchschnitt: newDataMinYLineDurchschnitt,
      })
    }

    if (dataMaxYLineVorteilhaft !== newDataMaxYLineVorteilhaft) {
      this.setState({
        ...this.state,
        dataMaxYLineVorteilhaft: newDataMaxYLineVorteilhaft,
      })
    }
  }
}

export default ForecastChart

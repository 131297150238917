/* @flow */
'use strict'

import type { Action } from './types'
import type { Partner, ProductCategory, ProdTyp, TransactionType, UserStatus } from '../reducers/main'

export function fetchAppDataAdmin(
  productCategories: Array<ProductCategory>,
  prodTyps: Array<ProdTyp>,
  userStati: Array<UserStatus>,
): Action {
  return {
    type: 'FETCH_APP_DATA_ADMIN',
    payload: {
      productCategories,
      prodTyps,
      userStati,
    },
  }
}

export function fetchAppDataUser(partner: Array<Partner>, productCategories: Array<ProductCategory>): Action {
  return {
    type: 'FETCH_APP_DATA_USER',
    payload: {
      partner,
      productCategories,
    },
  }
}

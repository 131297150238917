export const isCustomer = matched => matched === undefined

export const isB2cProduct = signupTypeID => signupTypeID === 0
export const isBavProduct = signupTypeID => signupTypeID === 1

export const isProductDepot = produktmantelID => produktmantelID === 1
export const isProductFLV = produktmantelID => produktmantelID === 2
export const isProductKLV = produktmantelID => produktmantelID === 3

export const isPartnerPlattform = partnerID => partnerID === 1
export const isPartnerHelvetia = partnerID => partnerID === 2
export const isPartnerZurich = partnerID => partnerID === 25
// export const isPartnerBaader = partnerID => partnerID === 27

// Document Types
export const getDoctypesForProduct = (produktmantelID, partnerID, isUmwandlung = false) => {
  if (isPartnerPlattform(partnerID)) {
    return ['depotvertrag', 'vermoegensverwaltungsvertrag']
  }

  if (isPartnerZurich(partnerID)) {
    if (isProductFLV(produktmantelID)) {
      if (!isUmwandlung) {
        return ['bav-zurich-risikoprofil']
      } else {
        return ['bav-zurich-risikoprofil', 'bav-einverstaendnis']
      }
    }

    if (isProductKLV(produktmantelID)) {
      if (isUmwandlung) {
        return ['bav-einverstaendnis']
      }
    }
  }

  return []
}

/* @flow */
'use strict'

import React, { Component } from 'react'
import Slider from 'react-slick'
import { Swipeable } from 'react-swipeable'

import SubMenu from 'components/UI/SubMenu'
import SLIDER_SETTINGS from 'core/sliders'

type Props = {
  children: Object,
  subMenuItems?: Array<string>,
  className?: string,
  setShowIndex?: (number) => void,
}

type State = {
  selected: number,
}

class CardContainer extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      selected: 0,
    }

    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.onSubMenuClick = this.onSubMenuClick.bind(this)
  }

  render() {
    const { children, subMenuItems, className } = this.props

    const sliderSettings = {
      ...SLIDER_SETTINGS,
      beforeChange: (current, next) => this.setState({ selected: next }),
    }

    if (!!subMenuItems && subMenuItems.length > 1) {
      return (
        <div className={'CardContainer ' + className}>
          <SubMenu items={subMenuItems} selected={this.state.selected} onClick={this.onSubMenuClick}></SubMenu>
          <Swipeable onSwipedLeft={this.next} onSwipedRight={this.prev} preventDefaultTouchmoveEvent={true}>
            <Slider ref={(c) => (this.slider = c)} {...sliderSettings}>
              {children}
            </Slider>
          </Swipeable>
        </div>
      )
    } else {
      return (
        <div className={'CardContainer ' + className}>
          {!!subMenuItems && (
            <SubMenu items={subMenuItems} selected={this.state.selected} onClick={this.onSubMenuClick}></SubMenu>
          )}
          {children}
        </div>
      )
    }
  }

  next(): void {
    this.slider.slickNext()
  }

  prev(): void {
    this.slider.slickPrev()
  }

  onSubMenuClick(id: string, index: number): void {
    this.props.setShowIndex(id)
    this.slider.slickGoTo(index)
  }
}

export default CardContainer

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n/*******************\n * JAVASCRIPT EXPORT\n *******************/\n", ""]);
// Exports
exports.locals = {
	"primary": "#0e3f60",
	"secondary": "#32d69a",
	"danger": "#ea1818",
	"chartLineColor1": "#32d69a",
	"chartLineColor2": "#959595",
	"chartLineColor3": "#0e3f60",
	"chartGridColor": "#f9f9f9",
	"portfolioDefaultColor": "#959595",
	"portfolioSharesColor": "#32d69a",
	"portfolioBondsColor": "#0e3f60",
	"portfolioCashColor": "#757b7f",
	"portfolioOtherColor": "#3c3b3b",
	"defaultFontSize": "16px"
};
module.exports = exports;

/* @flow */
'use strict'
import variables from '../stylesheets/utils/variables.scss'

// Reusable configurations for Highcharts

const basic = {
  credits: { enabled: false },
  legend: { enabled: false },
  title: { text: null },
}

const chart = {
  spacingLeft: 1,
  spacingRight: 0,
  style: {
    fontFamily: 'OpenSansRegular',
    color: variables.darkgray,
  },
}

const dataLabels = {
  align: 'left',
  crop: false,
  enabled: true,
  overflow: 'none',
  padding: 10,
  style: {
    fontFamily: 'OpenSansRegular',
    fontWeight: 400,
    fontSize: 12,
  },
  verticalAlign: 'middle',
}

const marker = {
  enabled: false,
  fillColor: '#fff',
  lineColor: '#0e3f60',
  lineWidth: 0.2,
  radius: 3,
  states: {
    hover: {
      enabled: true,
    },
  },
  symbol: 'circle',
}

const tooltip = {
  backgroundColor: '#0e3f60',
  borderColor: 'transparent',
  borderRadius: 8,
  borderWidth: 0,
  padding: 4,
  // always display tooltip centered and above point
  positioner: function(labelWidth: number, labelHeight: number, point: Object) {
    return {
      x: point.plotX + this.chart.series[0].xAxis.left - labelWidth / 2,
      y: point.plotY - (labelHeight + 4),
    }
  },
  shadow: false,
  style: {
    color: '#fff',
    fontSize: 11,
  },
}

const xAxis = {
  labels: {
    style: { color: variables.darkgray },
  },
  lineWidth: 1,
  lineColor: '#dee4e8',
  minPadding: 0,
  maxPadding: 0,
  tickWidth: 0,
  title: { text: null },
}

const yAxis = {
  gridLineWidth: 0,
  labels: { enabled: false },
  lineWidth: 1,
  lineColor: '#dee4e8',
  title: { text: null },
}

const HIGHCHARTS_CONFIG = { basic, chart, dataLabels, marker, tooltip, xAxis, yAxis }
export default HIGHCHARTS_CONFIG

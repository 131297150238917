import * as React from 'react'

function SvgIconBriefcase(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path d="M872 280v464H152V280h720m24-24H128v512h768V256z" />
      <path d="M616 152v128h256v86.7l-360 120-360-120V280h256V152h208m24-24H384v128H128v128l384 128 384-128V256H640V128z" />
    </svg>
  )
}

export default SvgIconBriefcase

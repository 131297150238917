// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.CardContainer {\n  height: 100%; }\n  .CardContainer > div:not(.subMenu) {\n    height: calc(100% - 50px); }\n  .CardContainer .slick-slider {\n    height: 100%; }\n    .CardContainer .slick-slider .slick-list {\n      height: 100%; }\n      .CardContainer .slick-slider .slick-list .slick-track {\n        height: 100%; }\n        .CardContainer .slick-slider .slick-list .slick-track .slick-slide {\n          height: 100%; }\n          .CardContainer .slick-slider .slick-list .slick-track .slick-slide > div {\n            height: 100%; }\n            .CardContainer .slick-slider .slick-list .slick-track .slick-slide > div .cardSlide {\n              height: 100%; }\n          .CardContainer .slick-slider .slick-list .slick-track .slick-slide:not(.slick-active) {\n            position: relative;\n            pointer-events: none;\n            z-index: -1; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

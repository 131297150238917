/* @flow */
'use strict'

import React, { Component } from 'react'
import classnames from 'classnames'

type Props = {
  className?: string,
  disabled?: boolean,
  link?: string,
  name: string | Object,
  selected?: boolean,
  style?: Object,
  target?: string,
  onClick?: (event: Object) => void,
  onTouchEnd?: (event: Object) => void,
}

type State = {
  dragged: boolean,
}

class Button extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      dragged: false,
    }

    // const Button = (this: any)
    // Button.onEvent = this.onEvent.bind(this)
  }

  render() {
    const { className, disabled, link, name, selected, style, target, onClick, onTouchEnd, ...other } = this.props

    const dragged = this.state.dragged

    const classes = classnames([{ disabled: disabled }, { selected: selected }, className])

    return link ? (
      <a
        className={classes ? `button ${classes}` : 'button'}
        href={link}
        target={target ? target : '_blank'}
        style={style}
        {...other}
      >
        {name}
      </a>
    ) : (
      <button
        className={classes}
        disabled={disabled || selected ? 'disabled' : false}
        style={style}
        onClick={onClick}
        onTouchStart={
          !!onTouchEnd
            ? () => {
                this.setState({ dragged: false })
              }
            : null
        }
        onTouchMove={
          !!onTouchEnd
            ? () => {
                if (!dragged) {
                  this.setState({ dragged: true })
                }
              }
            : null
        }
        onTouchEnd={
          !!onTouchEnd
            ? () => {
                if (this.state.dragged) {
                  return
                }
                onTouchEnd()
              }
            : null
        }
        {...other}
      >
        {name}
      </button>
    )
  }

  /// Event Handlers
  // ...
}

export default Button

import * as React from 'react'

function SvgIconTrend(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path d="M896 908H116V128h24v756h756v24z" />
      <path d="M256 784.97L239.03 768l207.11-207.11 158.01 94.81 224.17-352.27 20.25 12.89L611.85 688.3l-161.99-97.19L256 784.97z" />
    </svg>
  )
}

export default SvgIconTrend

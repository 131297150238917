'use strict'

import React from 'react'
import { separateDecimalNumber } from 'core/helpers'
import { isProductDepot, isPartnerPlattform, isPartnerZurich } from 'utils/matrix'
import ZurichLogo from 'components/UI/SVG/ZurichLogoNotext'

type Props = {
  currencySign: string,
  currentValue: number,
  name: string,
  profit: number,
  rendite: number,
  type: number,
  resources: Object,
  unternehmen: string,
}

function CardHeader({ productData, currencySign, currentValue, name, profit, rendite, type, resources, unternehmen }: Props) {
  const { integer, decimalPlace } = separateDecimalNumber(currentValue)
  const formattedProfit = separateDecimalNumber(profit)
  const formattedReturn = separateDecimalNumber((rendite * 100).toFixed(1))

  return (
    <div className="CardHeader">
      <div className="CardHeaderImage">
        <div className="categoryImage">
          {isPartnerZurich(productData.partner.id) ? (
            <ZurichLogo />
          ) : type === 1 ? (
            <img src="assets/images/categories/pension-icon.png" />
          ) : type === 2 ? (
            <img src="assets/images/categories/anlage-icon.png" />
          ) : type === 3 ? (
            <img src="assets/images/categories/kinder-icon.png" />
          ) : (
            <img src="assets/images/categories/sparen-icon.png" />
          )}
        </div>

        <div className="categoryName">
          <div className="categoryDiv">
            <h2>{name}</h2>
            {!!unternehmen && <div className="xs">{unternehmen}</div>}
          </div>
        </div>
      </div>

      <div className="info row-around">
        <div>
          <span className="label">{resources.value}</span>
          <div className="highlight">
            {currencySign} {integer},<span className="superscript">{decimalPlace}</span>
          </div>
        </div>

        {/* <div className="ProductCard__item">
          <h5 className="ProductCard__itemTitle">{resources.productCard.history.deposit}</h5>
          <div className="ProductCard__itemValue">
            {currencySign} {formattedDeposit.integer},
            <span className="superscript">{formattedDeposit.decimalPlace}</span>
          </div>
        </div> */}

        {isProductDepot(productData.produktmantel.id) && isPartnerPlattform(productData.partner.id) && (
          <div className="profit">
            <span className="label">{resources.profit}</span>
            <div className={profit >= 0 ? 'highlight color-success' : 'highlight'}>
              {`${profit >= 0 ? '+' : '-'}`} {currencySign} {Math.abs(formattedProfit.integer)},
              <span className="superscript">{formattedProfit.decimalPlace}</span>
            </div>
          </div>
        )}

        {isProductDepot(productData.produktmantel.id) && isPartnerPlattform(productData.partner.id) && (
          <div className="rendite">
            <span className="label">{resources.rendite}</span>
            <div className={rendite >= 0 ? 'highlight color-success' : 'highlight'}>
              {`${rendite >= 0 ? '+' : '-'}`} {Math.abs(formattedReturn.integer)}.{formattedReturn.decimalPlace}%
            </div>
          </div>
        )}

        {/* <div className="stand">
          <span className="label">Stand</span>
          <div className="highlight">{productData.bewertungsdatum}</div>
        </div> */}
      </div>
    </div>
  )
}

export default CardHeader

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
// import { syncHistoryWithStore } from 'react-router-redux'
// import { createBrowserHistory } from 'history'

import configureStore from './store/configureStore'
import AppRouter from './AppRouter'

const app = {
  initialize: function () {
    app.bindEvents()
  },
  bindEvents: function () {
    document.addEventListener('deviceready', app.onDeviceReady, false)
    document.addEventListener('resume', app.onDeviceResume, false)
  },
  onDeviceReady: function () {
    window.open = cordova.InAppBrowser.open
    StatusBar.overlaysWebView(false)
    StatusBar.styleDefault()
    app.branchInit()
  },
  onDeviceResume: function () {
    app.branchInit()
  },
  branchInit: function () {
    // Branch initialization
    Branch.initSession().then(function (data) {
      if (data['+clicked_branch_link']) {
        // read deep link data on click
        // alert("Deep Link Data: " + JSON.stringify(data));
      }
    })
  },
}

app.initialize()

const store = configureStore()

// Create an enhanced history that syncs navigation events with the store
// const history = syncHistoryWithStore(createBrowserHistory(), store)
// const history = syncHistoryWithStore(hashHistory, store)

render(
  <Provider store={store}>
    <AppRouter />
  </Provider>,
  document.querySelector('#app'),
)

/* @flow */
'use strict'

import type { Action } from './types'
import type { Product, User } from '../reducers/user'
import type { SignupData } from '../reducers/signup'

export function fetchInitialUser(userData: User | SignupData, userProducts: Array<Product>): Action {
  return {
    type: 'FETCH_INITIAL_USER',
    payload: {
      userData,
      userProducts,
    },
  }
}

export function logoutUser(): Action {
  return {
    type: 'LOGOUT',
  }
}

export function setHasLoggedOut(hasLoggedOut: boolean): Action {
  return {
    type: 'SET_HASLOGGEDOUT',
    payload: hasLoggedOut,
  }
}

/* @flow */
'use strict'

import React, { Component } from 'react'
import ReactHighcharts from 'react-highcharts'
import Modal from 'react-modal'
// import Switch from '@material-ui/core/Switch'

import { separateDecimalNumber } from 'core/helpers'
import HIGHCHARTS_CONFIG from 'core/highcharts'
import { isProductKLV } from 'utils/matrix'
import variables from 'config/variables.scss'

import type { Allocation } from 'reducers/User'

type Props = {
  currencySign: string,
  allocation: Array<Allocation>,
  allocationGroup: Array<Allocation>,
  positions?: Array<Position>,
  produktmantel?: number,
  resources?: Object,
  setShowInfo?: (number, boolean) => void,
}

type State = {
  showSelected: Array<boolean>,
  showDetails: boolean,
  showInfoModal: boolean,
}

class Investment extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      showSelected: [],
      showDetails: false,
      showInfoModal: false,
    }

    this.brightenColor = this.brightenColor.bind(this)
  }

  render() {
    const { product, allocationGroup, currencySign, positions, produktmantel, resources, setShowInfo } = this.props
    const { showSelected, showDetails, showInfoModal } = this.state
    let allocation = product.allocation.sort((a, b) => b.alloc - a.alloc)

    const categories = [
      {
        colorByPoint: true,
        data: [],
        size: showDetails ? '65%' : '100%',
      },
    ]

    if (showDetails) {
      categories.push({
        data: [],
        size: '100%',
        innerSize: '65%',
      })
    }

    let dataAvailable = false
    // calculate sums of all category values and alloc percentage
    let sumValue = 0
    let sumPercentage = 0

    allocation.forEach((allocation) => {
      const { alloc, name, value, color } = allocation

      // only add categories that have data
      if (alloc > 0) {
        // choose category color
        let categoryColor = '#' + color
        if (!color) {
          switch (name) {
            case 'Anleihen':
              categoryColor = variables.portfolioBondsColor
              break

            case 'Aktien':
              categoryColor = variables.portfolioSharesColor
              break

            case 'Festgeld':
            case 'Cash':
              categoryColor = variables.portfolioCashColor
              break

            case 'Immobilien':
              categoryColor = variables.portfolioDefaultColor
              break

            case 'Andere':
              categoryColor = variables.portfolioOtherColor
              break

            default:
              categoryColor = variables.portfolioDefaultColor
              break
          }
        }
        // set dataAvailable to true when at least one category has data
        if (!dataAvailable) {
          dataAvailable = true
        }

        let roundedPercentage
        if (isProductKLV(product.produktmantel.id)) {
          roundedPercentage = Math.round(alloc)
        } else {
          roundedPercentage = Math.round(alloc * 100)
        }

        // add value and rounded alloc percentage to sums
        sumValue += value
        sumPercentage += roundedPercentage
        const formattedValue = separateDecimalNumber(value)

        let drilldown = null
        if (showDetails) {
          let cats = []
          let data = []
          let value = []
          for (let i = 0; i < allocationGroup.length; i++) {
            if (allocationGroup[i].class == name) {
              cats.push(allocationGroup[i].name)
              data.push(Math.round(allocationGroup[i].alloc * 10000) / 100)
              value.push(allocationGroup[i].value)
            }
          }
          drilldown = {
            name,
            cats,
            data,
            value,
          }
        }

        categories[0].data.push({
          color: categoryColor ? categoryColor : null,
          y: roundedPercentage,
          name: name,
          value: formattedValue,
        })

        if (!!drilldown) {
          for (var j = 0; j < drilldown.data.length; j++) {
            const brightness = 0.2 + (j * 0.5) / drilldown.data.length
            categories[1].data.push({
              name: drilldown.cats[j],
              y: drilldown.data[j],
              color: !!resources.colors[i]
                ? '#' + this.brightenColor(resources.colors[i].substring(1), brightness)
                : null,
              class: name,
              value: drilldown.value[j],
            })
          }
        }
      }
    })

    const highChartsConfig = {
      ...HIGHCHARTS_CONFIG.basic,
      chart: {
        ...HIGHCHARTS_CONFIG.chart,
        height: '170',
        type: 'pie',
        margin: -5,
      },
      plotOptions: {
        pie: {
          enableMouseTracking: false,
          dataLabels: {
            distance: -23,
            formatter: function () {
              if (this.y >= 10) {
                // 2.49 will be rounded down, 2.5 will be rounded up
                return `${Math.round(this.y)}%`
              } else {
                return null
              }
            },
            style: {
              fontFamily: 'OpenSansSemibold',
              fontSize: 12,
              textOutline: 'none',
            },
          },
          innerSize: '45%',
          size: '100%',
        },
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        ...HIGHCHARTS_CONFIG.xAxis,
        visible: false,
      },
      yAxis: {
        ...HIGHCHARTS_CONFIG.yAxis,
        visible: false,
      },
      series: categories,
    }

    let alloArray = []
    if (!!allocationGroup) {
      alloArray = allocationGroup
    } else {
      positions.map((position) => {
        alloArray.push({
          name: position.kategorie,
          class: position.klasse,
          alloc: position.alloc,
          value: position.value,
        })
      })
    }

    return (
      <div className="Investment">
        {/* show chart only if data is available */}
        {dataAvailable ? (
          <div>
            <div className="InvestmentChart">
              <div style={{ width: produktmantel !== 3 ? '70%' : '100%', minWidth: '180px' }}>
                <ReactHighcharts config={highChartsConfig} />
              </div>

              {/* {produktmantel !== 3 && (
                <div className="InvestmentChart__toggle">
                  <div className="toggleLabel">{resources.lessDetails}</div>
                  <Switch
                    checked={showDetails}
                    color="primary"
                    // rippleStyle={{ justifyContent: 'center' }}
                    // iconStyle={{ margin: '0 auto' }}
                    onChange={(event) => {
                      setShowInfo(0, event.target.checked)
                      this.setState({ showDetails: event.target.checked })
                    }}
                  />
                  <div className="toggleLabel">{resources.moreDetails}</div>
                </div>
              )} */}

              {produktmantel === 3 && (
                <div className="investmentIcon" onClick={() => this.setState({ showInfoModal: true })}></div>
              )}
            </div>

            <div className="InvestmentLegend">
              {categories[0].data.map((category, cIx) => {
                const categoryName = category.name
                const value = category.value
                const color = category.color
                const percentage = category.y
                const hasAlloc = alloArray.some((el) => {
                  return el.class == categoryName
                })
                return (
                  <div key={`outer-cIx-${cIx}`}>
                    <div
                      className={
                        hasAlloc && showDetails
                          ? !!showSelected[categoryName]
                            ? 'LegendRow withIcon selected'
                            : 'LegendRow withIcon'
                          : 'LegendRow withIcon noArrow'
                      }
                      key={`cIx-${cIx}`}
                      onClick={
                        hasAlloc && showDetails
                          ? () => {
                              const arr = showSelected
                              arr[categoryName] = !!arr[categoryName] ? false : true
                              this.setState({ showSelected: arr })
                            }
                          : null
                      }
                    >
                      <div className="tableIcon">
                        <div style={{ backgroundColor: color }} className="legendIcon"></div>
                      </div>
                      <div className="tableLabel">
                        <span className="label">{categoryName}</span>
                      </div>
                      <div className="tableValue" style={{ width: showDetails ? 'calc(25% - 20px)' : '30%' }}>
                        <span className="label">{currencySign + ' ' + value.integer + ',' + value.decimalPlace}</span>
                      </div>
                      <div className="tablePercentage">
                        <span className="label">{percentage + '%'}</span>
                      </div>
                    </div>
                    {!!categories[1] && categories[1].data
                      ? categories[1].data
                          .filter((el) => {
                            return el.class == categoryName
                          })
                          .map((alloGroup, aIx) => {
                            const allocFormattedValue = separateDecimalNumber(alloGroup.value)
                            return (
                              <div
                                className="LegendRow"
                                key={`aIx-${aIx}`}
                                style={{ display: !!showSelected[categoryName] ? 'flex' : 'none' }}
                              >
                                <div className="tableIcon">
                                  <div style={{ backgroundColor: alloGroup.color }} className="legendIcon"></div>
                                </div>
                                <div className="tableLabel">
                                  <span className="label">{alloGroup.name}</span>
                                </div>
                                <div className="tableValue" style={{ width: showDetails ? 'calc(25% - 20px)' : '30%' }}>
                                  <span className="label">
                                    {currencySign +
                                      ' ' +
                                      allocFormattedValue.integer +
                                      ',' +
                                      allocFormattedValue.decimalPlace}
                                  </span>
                                </div>
                                <div className="tablePercentage">
                                  <span className="label">{alloGroup.y + '%'}</span>
                                </div>
                              </div>
                            )
                          })
                      : null}
                  </div>
                )
              })}
            </div>

            <Modal
              className="modalContent"
              overlayClassName="modalOverlay"
              contentLabel="Info"
              isOpen={showInfoModal}
              onRequestClose={() => this.setState({ showInfoModal: false })}
            >
              <p>{resources.infoIconModal}</p>
            </Modal>
          </div>
        ) : (
          <div className="no-data">{resources.nodata}</div>
        )}

        {/* dataAvailable
          ? <Button
              className="button2"
              name={resources.button}
              onClick={() => this.setState({ showDetails: ! showDetails })}
            />
          : null
        */}

        {/*<Modal
          className="modalContent bubble"
          overlayClassName="modalOverlay"
          portalClassName="InvestmentDetailsModal"
          contentLabel={resources.modal.title}
          isOpen={showDetails}
          onRequestClose={() => this.setState({ showDetails: ! showDetails })}
        >
          { showDetails
            ? <InvestmentDetails
                allocationGroup={allocationGroup}
                onClose={() => this.setState({ showDetails: ! showDetails })}
              />
            : null
          }
          <div className="modalShowPositions" onClick={showPositionsView}>{resources.modal.showPositions}</div>
        </Modal>
      */}
      </div>
    )
  }

  brightenColor(color: string, percent: number): string {
    const num = parseInt(color, 16),
      amt = Math.round(2.55 * percent * 100),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt

    return (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  }
}

export default Investment

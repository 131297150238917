'use strict'

import './InsideArea.scss'

import InsideArea from './InsideArea'
import Service from './Service'
import Postbox from './Postbox'
import Account from './Account'
import Dashboard from './Dashboard'
import Product from './Product'
import PDFPage from './PDFPage'
import Page from './Page'
import Message from './Message'
import Deposit from './Deposit'

export { InsideArea, Dashboard, Product, Service, Postbox, Account, PDFPage, Page, Message, Deposit }

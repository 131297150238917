import * as React from 'react'

const IconPeopleSafe = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 48 48"
    >
      <g fill="none" stroke="#0E3F60" stroke-linejoin="round" stroke-width="4">
        <path d="M6 9.25564L24.0086 4L42 9.25564V20.0337C42 31.3622 34.7502 40.4194 24.0026 44.0005C13.2521 40.4195 6 31.36 6 20.0287V9.25564Z" />
        <circle cx="24" cy="18" r="5" stroke-linecap="round" />
        <path stroke-linecap="round" d="M32 31C32 26.5817 28.4183 23 24 23C19.5817 23 16 26.5817 16 31" />
      </g>
    </svg>
  )
}

export default IconPeopleSafe

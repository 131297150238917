import queryString from 'query-string'

import request from 'core/request'
import { isAdmin } from 'core/helpers'
import config from 'config/config'

// AUTHENTICAION
export function login(email: string, password: string, callback: (data: Object) => void): void {
  const trimmedEmail = email.trim()
  const trimmedPassword = password.trim()
  const params = JSON.stringify({ username: trimmedEmail, password: trimmedPassword, uuid: config.mandantId, client: "app" })

  request({
    method: 'POST',
    url: `/session/login`,
    data: params,
  })
    .then((response) => {
      if (response.status === 'ok') {
        const userData = response.data

        // Check if admin
        if (isAdmin(userData.role)) {
          callback({ success: false, errDescription: 'Sie können sich hier nicht als Administrator anmelden.' })
        } else {
          // fetch user products, list of partner and list of product categories
          // as concurrent requests to save all data to store at once in callback
          Promise.all([request('/produkt'), request('/partner'), request('/typ/kategorien')])
            .then(([productsResponse, partnerResponse, categoriesResponse]) => {
              if (
                productsResponse.status === 'ok' &&
                partnerResponse.status === 'ok' &&
                categoriesResponse.status === 'ok'
              ) {
                const data = {
                  success: true,
                  userData: userData,
                  userProducts: productsResponse.data,
                  partner: partnerResponse.data,
                  productCategories: categoriesResponse.data,
                }
                callback(data)
              } else {
                // TODO: error handling
                console.error('error occured when fetching initial login data')
              }
            })
            .catch((error) => {
              // TODO: error handling
              console.error('error: ', error)
              callback({ success: false, error: error })
            })
        }
      } else {
        // TODO: error handling
        console.error('response error: ', response.data.error)

        callback({
          success: false,
          error: response.data.error,
          errNum: response.data.errNum,
          errDescription: response.data.description,
        })
      }
    })
    .catch((error) => {
      // TODO: error handling
      console.error('error: ', error)
      callback({ success: false, error: error })
    })
}

export function resetPassword(email: string, callback: (data: Object) => void): void {
  const trimmedEmail = email.trim()
  const params = JSON.stringify({ email: trimmedEmail })

  request({
    method: 'POST',
    url: `/request/create`,
    data: params,
  })
    .then((response) => {
      if (response.status === 'ok') {
        callback({ success: true })
      } else {
        // TODO: error handling
        console.error('response error: ', response.data.error)
        callback({ success: false, error: response.data.error })
      }
    })
    .catch((error) => {
      // TODO: error handling
      console.error('error: ', error)
      callback({ success: false, error: error })
    })
}

export function logout(callback: () => void): void {
  request({
    method: 'POST',
    url: `/session/logout`,
  })
    .then((response) => {
      if (response.status === 'ok') {
        callback()
      } else {
        // TODO: error handling
        console.error(response.error)
      }
    })
    .catch((error) => {
      // TODO: error handling
      console.error(error)
    })
}

export function loadPdf(url: string, type: number, callback: (data: Object) => void): void {
  // type 0 for system file path?
  // const requestURL = type === 0 ? path : path

  request(url).then((response) => {
    const blob = new Blob([response], { type: 'application/pdf' })
    callback({ success: true, pdfURL: blob })
  })
}

export function getCheckList(link: string, callback: (data: Object) => void) {
  request(link)
    .then((response) => {
      if (response.data.status === 'ok') {
        callback({ success: true, data: response.data.data })
      } else {
        callback({ success: false })
      }
    })
    .catch((error) => {
      callback({ success: false })
    })
}

// SCENARIO

/** API Call Query Parameter:
 * ende .................. Enddatum (Format: YYYYMMDD, optional, default = +25 Jahre)
 * einmalBetrag .......... Einmal einzahlen (optional, default = 0.0)
 * monatsBetrag .......... monatlicher Sparbetrag (optional, default = 0.0)
 * sparplan .............. Sparplantyp (1 = Einmalerlag, 2 = EinfachSparen, 3 = MorgenMehrSparen)
 * risiko ................ Risikoparameter (1-7 = Finabro Modellrechnung), (default: 0 = gesetzliche Modellrechnung)
 * productType ............... Produkt Typ / Mantel (defaul: 1 = Depot, 2 = Lebensversicherung)
 * startWert ............. Startwert (zB fuer bereits laufende Produkte, optional, default = 0.0)
 * einbezahltesKapital ... bereits vom Kunden einbezahltes Kapital (siehe produkt, default = 0.0)
 * wachstumsRate ......... wachstumsRate fuer MoergnMehrSparen (zb, 0.05 (fuer 5%), default = 0.0)
 * start ................. Startdatum (Format: YYYYMMDD, optional, default = heute)
 */

export function getScenarioData(
  ende: string,
  einmalBetrag: ?number,
  monatsBetrag: number,
  sparplanTyp: string,
  risiko: Risiko,
  prodTyp: ?number,
  startWert: ?number,
  einbezahltesKapital: ?number,
  wachstumsRate: ?number,
  start: ?string,
) {
  const sparplan =
    sparplanTyp === 'Einmalerlag'
      ? 1
      : sparplanTyp === 'EinfachSparen'
      ? 2
      : sparplanTyp === 'MorgenMehrSparen'
      ? 3
      : null

  const query = queryString.stringify({
    ende,
    einmalBetrag,
    monatsBetrag,
    sparplan,
    risiko,
    prodTyp,
    startWert,
    einbezahltesKapital,
    wachstumsRate,
    start,
  })

  return request(`/portfolio/scenario?${query}`)
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.TransactionsCard .TransactionsTable tbody tr:nth-child(even) {\n  background-color: #f5f9fc; }\n\n.TransactionsCard .TransactionsTable .green {\n  color: #32d69a; }\n\n.TransactionsCard .TransactionsTable .danger {\n  color: #0E3F60; }\n\n.TransactionsCard .TransactionsTable .grey {\n  color: #9e9e9e;\n  padding-top: 2px; }\n\n.TransactionsCard .MuiTableCell-root {\n  font-family: OpenSansRegular, \"Helvetica Neue\", Helvetica, sans-serif; }\n\n.TransactionsCard .MuiTableCell-body {\n  color: inherit; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

/* @flow */
'use strict'

import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { isBavProduct } from 'utils/matrix'
import ProductCard from 'components/Card/ProductCard'
import PositionsCard from 'components/Card/PositionsCard'
import TransactionsCard from 'components/Card/TransactionsCard'
import EmployerCard from 'components/Card/EmployerCard'
import CardHeader from 'components/UI/CardHeader'
import CardFooter from 'components/UI/CardFooter'

type Props = {
  resources?: Object,
  showInfo?: Array<boolean>,
  showIndex?: number,
  setShowInfo?: (number, boolean) => void,
  setShowIndex?: (number) => void,
}

const components = {
  product: ProductCard,
  positions: PositionsCard,
  transactions: TransactionsCard,
  employer: EmployerCard,
}

function Product({ resources, showInfo, showIndex, setShowInfo, setShowIndex }) {
  const location = useLocation()
  const match = useRouteMatch()
  const userData = useSelector((state) => state.user.data)
  const userProducts = useSelector((state) => state.user.products)
  const showFinabroModel = useSelector((state) => state.user.data.showFinabroModel)

  //ToDo: use index of URL to support muliple products for one consumer
  // const index = parseInt(match.params.index)
  const index = 0

  const tab = match.params.tab
  const {
    allocation,
    allocationGroup,
    depot,
    gesamtwert,
    gewinn,
    name,
    performance,
    produktmantel,
    rendite,
    sumEinzahlungen,
    sparplan,
    partner,
  } = userProducts[index]

  const currencySign = userProducts[index].konto.curr === 'USD' ? '$' : '€'
  const risiko = userProducts[index].musterPortfolio.risk
  const transactions = userProducts[index].konto.transaktionen
  const type = userProducts[index].produktkategorie.id
  const selectedResources = resources[tab]
  const Component = components[tab]

  const isBAVProduct = isBavProduct(userData.signupType.id)

  return (
    <div className="Product container">
      <div className="bubble">
        {selectedResources.hasHeader && (
          <CardHeader
            productData={userProducts[index]}
            currencySign={currencySign}
            currentValue={gesamtwert}
            name={name}
            profit={gewinn}
            rendite={rendite}
            type={type}
            resources={resources.cardHeader}
            produktmantel={produktmantel.id}
            unternehmen={isBAVProduct ? userData.unternehmen : null}
            dummyResources={resources}
          />
        )}

        <div
          className={selectedResources.hasHeader ? 'cardWrapper' : null}
          style={
            selectedResources.hasHeader
              ? location.pathname.indexOf('transactions') >= 0 || location.pathname.indexOf('positions') >= 0
                ? {
                    height: showInfo[showIndex] ? 'calc(100% - 137px)' : 'calc(100% - 219px)',
                    transform: showInfo[showIndex] ? 'translateY(-82px)' : 'translateY(0)',
                  }
                : { height: 'calc(100% - 219px)' }
              : { height: 'calc(100% - 56px)' }
          }
        >
          <Component
            product={userProducts[index]}
            allocation={allocation}
            allocationGroup={allocationGroup}
            currencySign={currencySign}
            currentValue={gesamtwert}
            deposit={sumEinzahlungen}
            depot={depot}
            performance={performance}
            rendite={rendite}
            risiko={risiko}
            savingsPlan={sparplan}
            typ={produktmantel}
            showFinabroModel={showFinabroModel}
            transactions={transactions}
            selectedMenu={match.params.splat} // needs updating?
            produktmantel={produktmantel.id}
            resources={selectedResources}
            setShowInfo={setShowInfo}
            setShowIndex={setShowIndex}
            isBAVProduct={isBAVProduct}
            geburtsDatum={userData.geburtsDatum}
          />
        </div>

        <CardFooter
          index={index}
          signupType={userData.signup_type}
          produktmantel={produktmantel.id}
          partnerid={partner.id}
          resources={resources.cardFooter}
          setShowIndex={setShowIndex}
        />
      </div>
    </div>
  )
}

export default Product

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.Input {\n  position: relative;\n  margin-bottom: 15px; }\n  .Input .labelFixed {\n    color: #959595;\n    margin-bottom: -10px; }\n  .Input .placeholderFixed {\n    position: absolute;\n    top: 38px;\n    left: 25px;\n    color: #959595; }\n  .Input .prefix {\n    position: absolute;\n    top: 24px;\n    left: 0;\n    color: #959595; }\n  .Input .inputLink {\n    display: block;\n    position: absolute;\n    bottom: 14px;\n    right: 0;\n    text-decoration: none;\n    color: rgba(0, 0, 0, 0.3);\n    text-align: center;\n    font-size: 0.8rem;\n    letter-spacing: 0.05em; }\n\ninput {\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n  -webkit-tap-highlight-color: transparent;\n  /* For some Androids */\n  /* outline: 0 for mozilla */ }\n  input::-moz-focus-inner {\n    border: 0; }\n\ninput::-ms-clear {\n  display: none; }\n\ninput:required {\n  box-shadow: none; }\n\ninput:invalid {\n  box-shadow: none; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

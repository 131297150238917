import * as React from 'react'

function SvgIconBack(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path d="M631.51 904.49L239.03 512l392.48-392.48 16.98 16.97L272.97 512l375.52 375.51-16.98 16.98z" />
    </svg>
  )
}

export default SvgIconBack

'use strict'

// TODO: REMOVE THIS FILE

import type { Partner, ProductCategory } from '../reducers/main'
import type { Performance, Portfolio, Product, Risiko, SavingsPlan, ScenarioData, User } from '../reducers/user'

// LAYOUT helpers
const PORTRAIT = 0
const LANDSCAPE = 90
export const MAX_SMALL_MOBILE_WIDTH = 479
export const MAX_MOBILE_WIDTH = 767
export const MAX_TABLET_WIDTH = 980

// DEVICE helpers
const iosPattern = /iPhone|iPad|iPod/i
export const isIos = iosPattern.test(navigator.userAgent)

export function getWindowWidth() {
  const windowWidth =
    window.screen.width && window.innerWidth && window.innerWidth > window.screen.width
      ? window.screen.width
      : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  return windowWidth
}

// format value with thousands separator, without decimal places
export function formatValue(value: number, rounding?: boolean): string {
  if (!rounding) {
    // uses bitwise operator to truncate decimal places to avoid unintended rounding up/down
    value = value | 0
  } else {
    value = Math.round(value)
  }

  // NOTE: below does not work on mobile
  // return value.toLocaleString('de-DE', { useGrouping: true })

  return value
    .toString()
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

// separate number before and after decimal point
export function separateDecimalNumber(value: number): Object {
  const integer = formatValue(value)
  const decimalPlace = (value % 1).toFixed(2).split('.')[1]

  return { integer, decimalPlace }
}

export function isAdmin(role: number): boolean {
  return role === 2 || role === 8
}

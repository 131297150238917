// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.Modal {\n  background: #ffffff;\n  font-family: OpenSansRegular, \"Helvetica Neue\", Helvetica, sans-serif;\n  position: relative;\n  outline: none;\n  max-height: 90vh;\n  max-width: 550px;\n  font-size: 0.9rem;\n  padding: 20px 40px;\n  white-space: pre-line;\n  overflow: auto;\n  -webkit-overflow-scrolling: touch; }\n\n.Modal__overlay {\n  background-color: rgba(180, 200, 214, 0.75);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 9999; }\n  .Modal__overlay .buttonContainer {\n    display: block;\n    text-align: center; }\n  .Modal__overlay .button {\n    color: #ffffff;\n    display: inline-block;\n    margin: 0;\n    margin-top: 1rem; }\n\n.Modal__close {\n  cursor: pointer;\n  position: absolute;\n  top: 1rem;\n  right: 1rem;\n  height: 1rem;\n  width: 1rem; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

import * as React from 'react'

function SvgIconShare(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path d="M426 281v319h319a296.11 296.11 0 01-567.8 91.21A295.92 295.92 0 01426 281m24-25c-176.73 0-320 143.27-320 320s143.27 320 320 320 320-143.27 320-320H450V256z" />
      <path d="M538 152.78A359.68 359.68 0 01873.22 488H538V152.78M514 128v384h384c0-212.08-171.92-384-384-384z" />
    </svg>
  )
}

export default SvgIconShare

/* @flow */
'use strict'

import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import adminReducer from './admin'
import mainReducer from './main'
import signupReducer from './signup'
import userReducer from './user'

export default combineReducers({
  admin: adminReducer,
  main: mainReducer,
  signup: signupReducer,
  user: userReducer,
  routing: routerReducer,
})

/* @flow */
'use strict'

import React, { useEffect } from 'react'
import { separateDecimalNumber } from '../../../core/helpers'

import CardContainer from '../../UI/CardContainer'

type Props = {
  currencySign: string,
  currentValue: number,
  resources: Object,
  setShowIndex?: (number) => void,
}

function EmployerCard({ currencySign, currentValue, setShowIndex, resources }) {
  const subMenuItems = [{ id: 'employer', title: resources.employer.title }]

  useEffect(() => {
    setShowIndex(subMenuItems[0].id)
  }, [])

  return (
    <CardContainer subMenuItems={subMenuItems} className="EmployerCard" setShowIndex={setShowIndex}>
      <div className="cardSlide">
        <table className="EmployerTable">
          <thead>
            <tr>
              <th style={{ width: '50%' }}>{resources.employer.heading.employer}</th>
              <th style={{ width: '50%' }}>{resources.employer.heading.capital}</th>
            </tr>
          </thead>
          <tbody>
            {resources.employer.dummy &&
              resources.employer.dummy.map((emp, empIx) => {
                const { employer, percent } = emp
                const formattedCapital = separateDecimalNumber(currentValue * percent)

                return (
                  <tr key={empIx}>
                    <td>{employer}</td>
                    <td>{currencySign + ' ' + formattedCapital.integer + ',' + formattedCapital.decimalPlace}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </CardContainer>
  )
}

export default EmployerCard

/* @flow */
'use strict'

import React, { ReactNode } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

type NavigationItem = {
  name: string,
  path: string,
  icon?: ReactNode,
}

type NavigationBarProps = {
  items: NavigationItem[],
}

const NavigationBar = ({ items }) => {
  const location = useLocation()
  const history = useHistory()
  return (
    <div className="NavigationBar">
      {items?.map((navItem, index) => {
        const { name, path, icon } = navItem
        return (
          <div
            key={`${name}-${index}`}
            className={`NavigationBar__item ${location.pathname.includes(path) ? 'selected' : ''}`}
            onClick={() => {
              history.push(path)
            }}
          >
            {icon && icon}
            <span className="NavigationBar__itemName">{name}</span>
          </div>
        )
      })}
    </div>
  )
}

export default NavigationBar

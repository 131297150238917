/* @flow */
'use strict'

import React, { useEffect } from 'react'
import { find, groupBy } from 'lodash'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import { separateDecimalNumber } from '../../../core/helpers'
import Investment from '../../ChartWrappers/Investment'
import CardContainer from '../../UI/CardContainer'

import type { Allocation, Depot } from '../../../reducers/user'

type Props = {
  allocation: Array<Allocation>,
  allocationGroup: Array<Allocation>,
  currencySign: string,
  currentValue: number,
  depot: Depot,
  produktmantel: number,
  setShowInfo?: (number, boolean) => void,
  setShowIndex?: (number) => void,
  resources?: Object,
}

const iconsStyles = {
  openInNew: {
    marginTop: '2px',
    width: '22px',
  },
}

function PositionsCard({
  product,
  allocation,
  allocationGroup,
  currencySign,
  currentValue,
  depot,
  produktmantel,
  setShowInfo,
  setShowIndex,
  resources,
}: Props) {
  const positions = []
  if (!!resources.positions && produktmantel !== 3) {
    const groupedPositions = groupBy(depot.positions, 'kategorie')
    let totalValueEUR = 0

    for (let category in groupedPositions) {
      groupedPositions[category].map((position, index) => {
        const { alloc, code, name, price, size, url, valueEUR } = position

        // TODO: add available currencies
        const formattedPrice = separateDecimalNumber(price)
        const formattedValueEUR = separateDecimalNumber(valueEUR)
        // const formattedAlloc = separateDecimalNumber((alloc * 100).toFixed(1))
        const formattedSize = separateDecimalNumber(size, 4)

        totalValueEUR += valueEUR

        /*
          const categoryName = category === 'Aktien Global'
            ? <p>Aktien Global (Europa, Nord-<br />amerika, Asien & Pazifik)</p> :
              category === 'Staatsanleihen Entwicklungsländer'
            ? <p>Staatsanleihen<br />Entwicklungsländer</p> :
              category
          */

        const categoryName = category

        positions.push(
          <div id={`${category}_${index}`} key={`${category}_${index}`}>
            <div className="flex-container">
              <div className="position-header">
                <div>{categoryName}</div>
                <div>{name}</div>
              </div>

              <div className="position-link">
                {url && (
                  <div onClick={() => window.open(url, '_system')}>
                    <OpenInNewIcon primary="#0E3F60" style={iconsStyles.openInNew} />
                  </div>
                )}
              </div>

              <div className="row-label">WERT:</div>
              <div className="row-value">
                {`€ ${formattedValueEUR.integer},`}
                <span className="superscript">{formattedValueEUR.decimalPlace}</span>
              </div>
              <div className="row-label">STÜCK:</div>
              <div className="row-value">{`${formattedSize.integer},${formattedSize.decimalPlace}`}</div>

              <div className="row-label">ANTEIL:</div>
              <div className="row-value">({`${(alloc * 100).toFixed(1).replace('.', ',')} %`})</div>
              <div className="row-label">KURS:</div>
              <div className="row-value">
                {`${currencySign} ${formattedPrice.integer},`}
                <span className="superscript">{formattedPrice.decimalPlace}</span>
              </div>
            </div>
            {/*
              ({(alloc * 100).toFixed(1)}%)

              Code: {code} <br />
              Preis: {`${currencySign}${formattedPrice.integer}`} <br />
              formattedPrice: <span className="superscript">{formattedPrice.decimalPlace}</span><br />
              formattedValueEUR d: <span className="superscript">{formattedValueEUR.decimalPlace}</span><br />
              */}
          </div>,
        )
      })
    }

    const bargeld = find(allocation, { name: 'Festgeld' })
    if (bargeld && bargeld.value > 0) {
      const { alloc, value } = bargeld
      const formattedValueEUR = separateDecimalNumber(value)

      totalValueEUR += value

      // TODO: check if push or unshift fits better after fixing sorting
      positions.unshift(
        <div id="cash" key="cash">
          <div className="flex-container">
            <div className="position-header">
              <b>Festgeld</b>
              <br />
            </div>

            <div className="position-link">&nbsp;</div>

            <div className="row-label">WERT:</div>
            <div className="row-value">{`${currencySign} ${formattedValueEUR.integer}.${formattedValueEUR.decimalPlace}`}</div>

            <div className="row-label">ANTEIL:</div>
            <div className="row-value">({(alloc * 100).toFixed(1)}%)</div>
          </div>
        </div>,
      )
    }
    const formattedTotalValueEUR = separateDecimalNumber(totalValueEUR)

    const cash = find(allocation, { name: 'Cash' })
    if (cash && cash.value > 0) {
      const { alloc, value } = cash
      const formattedValueEUR = separateDecimalNumber(value)

      totalValueEUR += value

      // TODO: check if push or unshift fits better after fixing sorting
      positions.unshift(
        <div id="cash" key="cash">
          <div className="flex-container">
            <div className="position-header">
              <b>Cash</b>
              <br />
            </div>

            <div className="position-link">&nbsp;</div>

            <div className="row-label">WERT:</div>
            <div className="row-value">{`${currencySign} ${formattedValueEUR.integer}.${formattedValueEUR.decimalPlace}`}</div>

            <div className="row-label">ANTEIL:</div>
            <div className="row-value">({(alloc * 100).toFixed(1)}%)</div>
          </div>
        </div>,
      )
    }
  }

  const subMenuItems = [
    {
      id: 'investment',
      title: resources.investment.title,
    },
    !!resources.positions && produktmantel !== 3
      ? {
          id: 'positions',
          title: resources.positions.title,
        }
      : null,
  ].filter((el) => el)

  useEffect(() => {
    setShowIndex(subMenuItems[0].id)

    return () => {
      setShowInfo('investment', false)
      setShowInfo('positions', false)
    }
  }, [])

  return (
    <CardContainer subMenuItems={subMenuItems} className="PositionsCard" setShowIndex={setShowIndex}>
      <div
        className="cardSlide"
        onScroll={(event) => {
          // add CardHeader height (82px) to offsetHeight otherwise the user can't scroll up again
          if (event.target.scrollHeight > event.target.offsetHeight) {
            setShowInfo('investment', event.target.scrollTop > 0)
          }
        }}
      >
        <Investment
          product={product}
          allocationGroup={allocationGroup}
          currencySign={currencySign}
          currentValue={currentValue}
          positions={depot.positions}
          produktmantel={produktmantel}
          resources={resources.investment}
          setShowInfo={setShowInfo}
        />
      </div>

      {!!resources.positions && produktmantel !== 3 && (
        <div
          className="cardSlide"
          onScroll={(event) => {
            // add CardHeader height (82px) to offsetHeight otherwise the user can't scroll up again
            if (event.target.scrollHeight > event.target.offsetHeight) {
              setShowInfo('positions', event.target.scrollTop > 0)
            }
          }}
        >
          <div className="positionsWrapper">{positions.reverse()}</div>
        </div>
      )}
    </CardContainer>
  )
}

export default PositionsCard

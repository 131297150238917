import * as React from 'react'

const IconPeopleMoney = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M5.5 7a2.5 2.5 0 1 1 5 0a2.5 2.5 0 0 1-5 0ZM8 3a4 4 0 1 0 0 8a4 4 0 0 0 0-8Zm7.5 5a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0ZM17 5a3 3 0 1 0 0 6a3 3 0 0 0 0-6Zm-5.25 8c.301 0 .588.06.851.166a2.509 2.509 0 0 0-1.393 1.334H4.25a.75.75 0 0 0-.75.75v.257l.007.08c.007.074.023.188.055.329c.066.281.198.656.459 1.029C4.514 17.65 5.578 18.5 8 18.5c1.407 0 2.355-.287 3-.665v1.661c-.791.312-1.777.504-3 .504c-2.828 0-4.39-1.025-5.208-2.195a4.487 4.487 0 0 1-.778-2.07A3.021 3.021 0 0 1 2 15.529v-.278A2.25 2.25 0 0 1 4.25 13h7.5Zm.25 2.5a1.5 1.5 0 0 1 1.5-1.5h8a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5h-8a1.5 1.5 0 0 1-1.5-1.5v-4Zm1 .5v1a2 2 0 0 0 2-2h-1a1 1 0 0 1-1 1Zm9 1v-1a1 1 0 0 1-1-1h-1a2 2 0 0 0 2 2Zm-2 3h1a1 1 0 0 1 1-1v-1a2 2 0 0 0-2 2Zm-7-2v1a1 1 0 0 1 1 1h1a2 2 0 0 0-2-2Zm4.5 1.25a1.75 1.75 0 1 0 0-3.5a1.75 1.75 0 0 0 0 3.5Z"
      />
    </svg>
  )
}

export default IconPeopleMoney

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.ForecastChart {\n  height: calc(100% - 55px); }\n  .ForecastChart .highcharts-legend-item > text {\n    cursor: default !important;\n    color: '#515355'; }\n  .ForecastChart .targetAmountInfo {\n    margin: 0; }\n\n.ForecastChart__labels {\n  display: flex;\n  justify-content: space-between;\n  font-size: 0.7rem;\n  padding: 0 5px; }\n  .ForecastChart__labels .xAxis-fixed-label {\n    color: #757b7f;\n    flex: 1;\n    max-width: 100px;\n    z-index: 1; }\n    .ForecastChart__labels .xAxis-fixed-label.right {\n      text-align: right; }\n  .ForecastChart__labels .ForecastChart__endDateToggle {\n    color: #0E3F60;\n    flex: 1;\n    text-align: center; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

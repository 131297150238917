'use strict'

import React from 'react'

type Props = {
  items: Array<String>,
  selected: number,
  onClick: (number) => void,
}

function SubMenu({ items, selected, onClick }: Props) {
  return (
    <div className="subMenu">
      {items.map((item, index) => {
        return index === selected ? (
          <div className="selected" key={item.id} style={{ width: 100 / items.length + '%' }}>
            {item.title}
          </div>
        ) : (
          <div key={item.id} onClick={() => onClick(item.id, index)} style={{ width: 100 / items.length + '%' }}>
            {item.title}
          </div>
        )
      })}
    </div>
  )
}

export default SubMenu

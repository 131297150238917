// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n/*******************\n * JAVASCRIPT EXPORT\n *******************/\n\n.NavigationBar {\n  border-top: 1px solid #e0e0e0;\n  background: #fff;\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  font-size: 0.6rem;\n  width: 100%;\n  height: 70px;\n  z-index: 3; }\n  .NavigationBar__item {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n    text-decoration: none; }\n    .NavigationBar__item > svg {\n      width: 2rem;\n      height: 2rem;\n      max-width: 2rem;\n      max-height: 2rem;\n      color: #0e3f60; }\n    .NavigationBar__item.selected {\n      background: #eaf5fc;\n      font-weight: bold;\n      opacity: 1; }\n  .NavigationBar__itemName {\n    margin-top: 0.5rem; }\n  .NavigationBar__itemImage {\n    width: 24px; }\n", ""]);
// Exports
exports.locals = {
	"primary": "#0e3f60",
	"secondary": "#32d69a",
	"danger": "#ea1818",
	"chartLineColor1": "#32d69a",
	"chartLineColor2": "#959595",
	"chartLineColor3": "#0e3f60",
	"chartGridColor": "#f9f9f9",
	"portfolioDefaultColor": "#959595",
	"portfolioSharesColor": "#32d69a",
	"portfolioBondsColor": "#0e3f60",
	"portfolioCashColor": "#757b7f",
	"portfolioOtherColor": "#3c3b3b",
	"defaultFontSize": "16px"
};
module.exports = exports;

/* @flow */
'use strict'

import { Box, Grid } from '@material-ui/core'
import React, { ReactNode, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

type ProductContainerProps = {
  children: ReactNode,
}

const ProductContainer = ({ children }) => {
  return <div className="ProductContainer">{children}</div>
}

export default ProductContainer

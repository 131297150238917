import * as React from 'react'

const IconLineGraph = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="m.69 11.331l1.363.338l1.026-1.611l-1.95-.482a.904.904 0 1 0-.439 1.755zm17.791.261l-4.463 4.016l-5.247-4.061a.905.905 0 0 0-.338-.162l-.698-.174l-1.027 1.611l1.1.273l5.697 4.408a.915.915 0 0 0 1.168-.043l5.028-4.527a.9.9 0 0 0 .064-1.277a.912.912 0 0 0-1.284-.064zM8.684 7.18l4.887 3.129a.913.913 0 0 0 1.24-.246l5.027-7.242a.902.902 0 0 0-.231-1.26a.91.91 0 0 0-1.265.23l-4.528 6.521l-4.916-3.147a.915.915 0 0 0-.688-.123a.914.914 0 0 0-.571.4L.142 17.209A.903.903 0 0 0 .908 18.6a.908.908 0 0 0 .768-.42l7.008-11z"
      />
    </svg>
  )
}

export default IconLineGraph

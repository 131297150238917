/* @flow */
'use strict'

import React from 'react'
import { Box } from '@material-ui/core'

type ProductProvider = {
  name: string,
  key: string,
  countryIso3: string,
  downloadLinkLogo: string,
}

type ProductHeaderProps = {
  customName: string,
  unternehmen?: string,
  productProvider?: ProductProvider,
}

const ProductHeader = ({ customName, unternehmen, productProvider }) => {
  const accessToken = localStorage.getItem('accessToken')
  return (
    <div className="ProductHeader">
      <Box className="ProductHeader__content" display="flex">
        {productProvider?.downloadLinkLogo && (
          <Box className="ProductHeader__icon" display="flex" alignItems="center" justifyContent="center">
            <img src={`${productProvider?.downloadLinkLogo}&access_token=${accessToken}`} />
          </Box>
        )}
        <Box display="flex" flexDirection="column" justifyContent="center">
          {customName && <h2>{customName}</h2>}
          {unternehmen && <div className="xs">{unternehmen}</div>}
        </Box>
      </Box>
    </div>
  )
}

export default ProductHeader

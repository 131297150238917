/* @flow */
'use strict'

import React, { useState, useEffect } from 'react'

type Props = {
  text: ?String,
  delay: ?Number,
  showSpinner: ?Boolean,
}

function LoadingSpinner({ text, delay = 250, showSpinner = true }: Props) {
  const [showLoading, setshowLoading] = useState(false)

  // only show spinner after delay
  useEffect(() => {
    const timer = setTimeout(() => setshowLoading(true), delay)
    return () => clearTimeout(timer)
  })

  if (showLoading) {
    return (
      <div className="LoadingSpinner">
        {showSpinner && (
          <img className="LoadingSpinner__image" src="assets/images/UI/loading-spinner.png" alt="Loading..." />
        )}

        {text && <div className="LoadingSpinner__text">{text}</div>}
      </div>
    )
  }

  return null
}

export default LoadingSpinner

/* @flow */
'use strict'

import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import MessageService from 'services/api/message'
import IconAttachment from 'components/UI/SVG/IconAttachment'

type Props = {
  setPostBoxNew?: () => void,
}

function Message({ setPostBoxNew }: Props) {
  const history = useHistory()
  const params = useParams()
  const [message, setMessage] = useState(null)

  async function fetchMessage() {
    const messageResponse = await MessageService.get(params.id)
    setMessage(messageResponse.data)

    const messagesResponse = await MessageService.list()
    const { messages } = messagesResponse.data
    setPostBoxNew(messages.filter((el) => !el.readdate).length)
  }

  const onDownloadAttachment = () => {
    history.push(
      `/inside/attachment/${encodeURIComponent(message.subject)}/1/${encodeURIComponent(
        `message/attachment?msgid=${params.id}&attachmentid=${message.attachments[0]}`,
      )}`,
    )
  }

  useEffect(() => {
    fetchMessage()
  }, [])

  return (
    <div className="Message container infoBox">
      {!!message && (
        <div className="column bubble">
          <div className="heading">
            <h2>{message.subject}</h2>
          </div>

          {message.attachments && message.attachments.length > 0 && (
            <small className="Message__infoAttachment" onClick={() => onDownloadAttachment()}>
              <>
                <IconAttachment />
                {message.attachments.length} {message.attachments.length > 1 ? 'Anhänge' : 'Anhang'}
                <div className="Message__download">Anzeigen</div>
              </>
            </small>
          )}

          <div className="Message__body" dangerouslySetInnerHTML={{ __html: message.body }} />
        </div>
      )}
    </div>
  )
}

export default Message

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.HistoryChart {\n  position: relative; }\n  .HistoryChart .xAxis-year {\n    margin-top: 10px;\n    font-size: 0.6rem;\n    text-align: center; }\n  .HistoryChart .xAxis-fixed-label {\n    position: absolute;\n    top: 297px;\n    font-size: 0.9rem;\n    color: #757b7f;\n    z-index: 1; }\n    .HistoryChart .xAxis-fixed-label.right {\n      right: 0; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

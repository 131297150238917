/* @flow */
'use strict'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import LoadingSpinner from '../../components/UI/LoadingSpinner'

type State = {
  loading: boolean,
}

class Page extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
    }

    this.setIframeDimensions = this.setIframeDimensions.bind(this)
  }

  componentDidMount() {
    this.pageIframe.onload = () => {
      try {
        const doc = this.pageIframe.contentDocument
          ? this.pageIframe.contentDocument
          : this.pageIframe.contentWindow.document
        const observer = new MutationObserver(this.setIframeDimensions)
        observer.observe(doc.body, { attributes: true, childList: true, characterData: true, subtree: true })
      } catch (e) {
        this.pageIframe.style.visibility = 'visible'
        this.pageIframe.scrolling = 'yes'
      }
      this.setState({ loading: false })
    }
  }

  render() {
    const { url } = this.props.match.params

    return (
      <div className="Page container infoBox">
        <div className="column bubble" ref={ref => (this.pageColumn = ref)}>
          <iframe
            ref={ref => (this.pageIframe = ref)}
            src={decodeURIComponent(url)}
            style={{ width: '100%', height: '100%', border: 0, visibility: 'hidden' }}
            sandbox="allow-scripts allow-same-origin"
          />
          {this.state.loading ? (
            <div
              style={{
                position: 'absolute',
                alignSelf: 'center',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 5,
              }}
            >
              <LoadingSpinner />
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  setIframeDimensions() {
    const doc = this.pageIframe.contentDocument
      ? this.pageIframe.contentDocument
      : this.pageIframe.contentWindow.document
    const body = doc.body,
      html = doc.documentElement
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    )
    const width = window.innerWidth
    this.pageIframe.style.visibility = 'hidden'
    body.style.maxWidth = '100vw'
    this.pageIframe.style.height = height + 'px'
    this.pageColumn.style.height = height + 1 + 'px'
    this.pageIframe.style.visibility = 'visible'
  }
}

export default withRouter(Page)

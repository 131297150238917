'use strict'

import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { isIos } from 'core/helpers'
import Button from 'components/UI/Button'
import CardFooter from 'components/UI/CardFooter'

type Props = {
  user: Object,
  userProducts: Object,
  resources?: Object,
  setShowIndex?: (number) => void,
}

function Deposit({ resources, setShowIndex }: Props) {
  const params = useParams()
  const userData = useSelector((state) => state.user.data)
  const userProducts = useSelector((state) => state.user.products)

  const iban = !!userProducts[params.index].konto.iban ? userProducts[params.index].konto.iban : null

  return (
    <div className="Deposit container infoBox">
      <div className="column bubble">
        <div className="heading">
          <h2>{resources.deposit.title}</h2>
        </div>
        <div className="block">{resources.deposit.text1}</div>
        <div className="block">{resources.deposit.text2}</div>
        <div className="block">{resources.deposit.text3}</div>
        <div className="block">
          <div>IBAN: {!!iban ? iban.replace(/\s/g, '') : null}</div>
          <div>Institut: {resources.deposit.institut}</div>
        </div>

        <Button
          name="IBAN kopieren"
          style={{ margin: '15px auto 0 auto' }}
          onClick={() =>
            cordova.plugins.clipboard.copy(
              iban.replace(/\s/g, ''),
              window.plugins.toast.showWithOptions({
                message: 'IBAN erfolgreich kopiert',
                duration: '1000',
                position: 'bottom',
                styling: {
                  opacity: isIos ? 0.5 : 0.75,
                  textSize: isIos ? 11 : 13,
                  cornerRadius: isIos ? 16 : 100,
                  horizontalPadding: isIos ? 14 : 30,
                  verticalPadding: isIos ? 10 : 20,
                },
              }),
            )
          }
        />

        <CardFooter
          index={params.index}
          signupType={userData.signup_type}
          produktmantel={userProducts[params.index].produktmantel.id}
          partnerid={userProducts[params.index].partner.id}
          resources={resources.cardFooter}
          setShowIndex={setShowIndex}
        />
      </div>
    </div>
  )
}

export default Deposit

/* @flow */
'use strict'

import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'

type Props = {
  messagesUnreadCount?: number,
}

function HeaderBar({ messagesUnreadCount }: Props) {
  const location = useLocation()
  const history = useHistory()
  const userProducts = useSelector((state) => state.user.products)

  // check if last char is a number
  const isSubmenuScreen = !isNaN(parseInt(location.pathname.slice(-1))) || location.pathname.includes('deposit')
  const isDashboardScreen = location.pathname === '/inside'
  const isProductScreen = location.pathname.includes('inside/0')
  const hasEinzahlung = userProducts[0]?.sumEinzahlungen > 0

  function goBack() {
    if (isSubmenuScreen) {
      history.goBack()
    } else {
      if (hasEinzahlung) {
        history.push('/inside/0/product/')
      }

      if (isProductScreen || !hasEinzahlung) {
        history.push('/inside')
      }
    }
  }

  return (
    <div className="HeaderBar">
      <div className="left">
        {!isDashboardScreen && (
          <img className="icon" src="assets/images/UI/icon_family_pfade_back.svg" onClick={goBack} />
        )}
      </div>

      <img className="logo" src="assets/images/logo-small.png" onClick={() => history.push('/inside')} />

      <div className="right">
        <Link
          className={`HeaderBar__item HeaderBar__item--service ${
            location.pathname.includes('service') ? 'is-active' : ''
          }`}
          to="/inside/service"
        >
          <img className="icon" src="assets/images/UI/icon_family_pfade_information.svg" />
        </Link>

        <Link
          className={`HeaderBar__item HeaderBar__item--messages ${
            location.pathname.includes('postbox') ? 'is-active' : ''
          }`}
          to="/inside/postbox"
        >
          <img className="icon" src="assets/images/UI/icon_family_pfade_envelope.svg" />
          {messagesUnreadCount > 0 && <div className="HeaderBar__itemIndicator">{messagesUnreadCount}</div>}
        </Link>

        <Link
          className={`HeaderBar__item HeaderBar__item--account ${
            location.pathname.includes('konto') ? 'is-active' : ''
          }`}
          to="/inside/konto"
        >
          <img className="icon" src="assets/images/UI/icon_family_pfade_profile.svg" />
        </Link>
      </div>
    </div>
  )
}

export default HeaderBar

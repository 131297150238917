/* @flow */
'use strict'

import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import FastClick from 'fastclick'

import { resetPassword } from 'core/api'
import { setHasLoggedOut } from 'actions/user'

import Input from 'components/UI/Input'
import Button from 'components/UI/Button'

import type { Dispatch } from 'actions/types'
import type { User, Product } from 'reducers/user'

type Props = {
  dispatch: Dispatch,
  resources?: Object,
}

type State = {
  email: ?string,
  error: ?string,
  resetDisabled: ?boolean,
  success: ?boolean,
  hideLoginLinks: ?boolean,
}

class PasswordReset extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      email: null,
      error: null,
      resetDisabled: true,
      success: false,
      hideLoginLinks: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  // componentDidMount() {
  //   FastClick.attach(document.getElementById('submitButton'))
  //   FastClick.attach(document.getElementById('backLink'))
  // }

  render() {
    const { history } = this.props
    const resources = this.props.resources.forgotPassword
    const { email, error, resetDisabled, success, hideLoginLinks } = this.state

    return (
      <div className="PasswordReset container">
        <div className="column">
          <div className="logo"></div>
          {success ? (
            <div>
              <h1>{resources.title}</h1>
              <h5 style={{ marginTop: 50, marginBottom: 30 }}>{resources.success.text}</h5>
            </div>
          ) : (
            <h5 style={{ marginTop: '20%', height: '32px' }}>{resources.initial.text}</h5>
          )}

          {success ? (
            <Button
              name={resources.success.button}
              className="returnLogin"
              onClick={() => {
                history.push('/')
              }}
            />
          ) : (
            <div className="credentials">
              <Input
                type="email"
                floatingLabelText={resources.initial.input1.label}
                hintText={resources.initial.input1.hint}
                maxLength={50}
                style={{ fontSize: '0.9rem' }}
                tabIndex={1}
                value={email ? email : ''}
                onChange={(event: Object, text: string) => {
                  this.setState({ email: text })
                  if (!!text) {
                    this.setState({ resetDisabled: false })
                  } else {
                    this.setState({ resetDisabled: true })
                  }
                }}
                onFocus={() => {
                  this.setState({ hideLoginLinks: true })
                }}
                onBlur={() => {
                  this.setState({ hideLoginLinks: false })
                }}
                onSubmit={this.onSubmit}
              />

              {error && <p className="error">{error}</p>}

              <Button
                name={resources.initial.button}
                tabIndex={3}
                onClick={this.onSubmit}
                id="submitButton"
                disabled={resetDisabled}
              />

              {!hideLoginLinks && (
                <Link
                  to="/"
                  id="backLink"
                  className="loginLinks bottom"
                  onClick={() => {
                    this.props.dispatch(setHasLoggedOut(true))
                  }}
                >
                  {resources.initial.return}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  onSubmit(event: Object): void {
    const { email, resetDisabled } = this.state

    if (!!email) {
      resetPassword(email, (data: Object) => {
        this.setState({
          error: null,
          success: true,
        })
      })
    } else {
      this.setState({
        error: this.props.resources.forgotPassword.error,
        resetDisabled: true,
      })
    }
  }
}

export default connect()(withRouter(PasswordReset))

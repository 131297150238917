/* @flow */
'use strict'

import React from 'react'
import ReactModal from 'react-modal'

type Props = {
  className: ?string,
  contentLabel: ?string,
  hideCloseButton: ?boolean,
  children: ?Object,
}

ReactModal.setAppElement('#app')

function Modal({ className, contentLabel, hideCloseButton, children, ...props }: Props) {
  return (
    <ReactModal
      className={`Modal ${className}`}
      overlayClassName="Modal__overlay"
      contentLabel={`Info ${contentLabel}`}
      {...props}
    >
      {!hideCloseButton && (
        <img className="Modal__close" src="assets/images/UI/close.png" onClick={props.onRequestClose} />
      )}

      {children}
    </ReactModal>
  )
}

export default Modal

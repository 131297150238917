/* @flow */
'use strict'

import type { Action } from '../actions/types'

export type Partner = {
  id: number,
  name: string,
  konto: Konto,
}

type Konto = {
  id: number,
  nummer: string,
  name: string,
  curr: string,
  institut: string,
  iban: string,
  bic: string,
}

export type ProductCategory = {
  id: number,
  name: string,
}

export type ProdTyp = {
  id: number,
  name: string,
}

export type TransactionType = {
  id: number,
  name: string,
}

export type UserStatus = {
  id: number,
  name: string,
}

type State = {
  partner: Array<Partner>,
  productCategories: Array<ProductCategory>,
  prodTyps: Array<ProdTyp>,
  transactionTypes: Array<TransactionType>,
  userStati: Array<UserStatus>,
}

const initialState: State = {
  partner: [],
  productCategories: [],
  prodTyps: [],
  transactionTypes: [],
  userStati: [],
}

export default function mainReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_APP_DATA_ADMIN':
      return fetchAppDataAdmin(state, action)

    case 'FETCH_APP_DATA_USER':
      return fetchAppDataUser(state, action)

    default:
      return state
  }
}

// reducers

function fetchAppDataAdmin(state, action): State {
  const { productCategories, prodTyps, userStati } = action.payload

  return {
    ...state,
    productCategories,
    prodTyps,
    userStati,
  }
}

function fetchAppDataUser(state, action): State {
  const { partner, productCategories } = action.payload

  return {
    ...state,
    partner,
    productCategories,
  }
}

/* @flow */
'use strict'

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import add from 'date-fns/add'

import { getScenarioData } from 'core/api'
import date from 'utils/date'

import ForecastChart from '../../Charts/ForecastChart'
import LoadingOverlay from '../../UI/LoadingOverlay'
import Modal from 'components/UI/Modal'
import ErrorBoundary from 'containers/General/ErrorBoundary'

import type { Risiko, ScenarioData, SavingsPlan } from 'reducers/User'

type Props = {
  currencySign: string,
  currentValue: number,
  deposit: number,
  rendite: number,
  risiko: Risiko,
  savingsPlan: SavingsPlan,
  typ: Object,
  geburtsDatum: string,
  resources?: Object,
}

type State = {
  endDate: string,
  isLoading: boolean,
  scenarioData: ?ScenarioData,
  showFinabroModel: boolean,
}

function Forecast({ currencySign, currentValue, resources }: Props) {
  const userData = useSelector((state) => state.user.data)
  const productData = useSelector((state) => state.user.products[0])

  const [endDate, setEndDate] = useState(getDefaultEndDate())
  const [showEndDateModal, setShowEndDateModal] = useState(false)
  const [showHintModal, setShowHintModal] = useState(false)
  const [showLegendHintModal, setShowLegendHintModal] = useState(false)
  const [forecastState, setForecastState] = useState({
    loading: false,
    data: null,
    error: false,
  })

  const { gesamtwert, produktmantel, musterPortfolio, sparplan, sumEinzahlungen, waehrung } = productData

  // disabled / always show FINABRO model
  // switch between model calculations
  // risiko (1-7 = Finabro Modellrechnung), (default: 0 = gesetzliche Modellrechnung)
  // const risikoState = showFinabroModel ? musterPortfolio.risk : 0
  const risikoState = musterPortfolio.risk

  function getDefaultEndDate() {
    return userData.signupType.id === 1 ? 'Pensionsantritt' : '10 Jahre'
  }

  async function fetchChartData() {
    setForecastState({ loading: true, data: null, error: false })

    let endDateTimestamp
    switch (endDate) {
      case '5 Jahre':
        // 5 years from now
        endDateTimestamp = date.format(
          add(new Date(), {
            years: 5,
          }),
          'yyyyMMdd',
        )
        break
      case '10 Jahre':
        // 10 years from now
        endDateTimestamp = date.format(
          add(new Date(), {
            years: 10,
          }),
          'yyyyMMdd',
        )
        break
      case 'Pensionsantritt':
        // age 65
        endDateTimestamp = date.format(
          add(date.parse(userData.geburtsDatum, 'dd.MM.yyyy'), {
            years: 65,
          }),
          'yyyyMMdd',
        )
        break
    }

    try {
      const roundedSparplan = {
        ...sparplan,
        betrag: Math.round(sparplan.betrag),
      }

      const response = await getScenarioData(
        endDateTimestamp,
        null,
        roundedSparplan.betrag,
        roundedSparplan.typ,
        risikoState,
        produktmantel.id,
        gesamtwert,
        sumEinzahlungen,
        roundedSparplan.wachstumsRate,
        null,
      )

      setForecastState({ loading: false, data: response.data, error: false })
    } catch (error) {
      console.error(error)
      setForecastState({ loading: false, data: null, error: true })
    }
  }

  function renderLegendHintModalText(type) {
    switch (type) {
      case 'average':
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: produktmantel.id === 3 ? resources.durchschnittlichTextBAV : resources.durchschnittlichText,
            }}
          />
        )
      case 'bad':
        return <p dangerouslySetInnerHTML={{ __html: resources.schlechtText }} />
      case 'deposited':
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: produktmantel.id === 3 ? resources.einbezahltTextBAV : resources.einbezahltText,
            }}
          />
        )
      case 'profit-sharing':
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: resources.profitSharing.replace(
                '##estimatedIncreaseInValue##',
                productData.sparplan.wachstumsRate?.toLocaleString('de') ?? '2',
              ),
            }}
          />
        )
      case 'guaranteed':
        return <p dangerouslySetInnerHTML={{ __html: resources.guaranteed }} />
      default:
        return <p>Keine Informationen</p>
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [endDate])

  return (
    <div className="Forecast chartContainer">
      {forecastState.loading && <LoadingOverlay loadWithTimeout />}

      {!forecastState.loading && !forecastState.error && forecastState.data && (
        <ErrorBoundary errorText="Fehler beim Zeichnen des Charts.">
          <ForecastChart
            rows={forecastState.data.series}
            columns={forecastState.data.columns}
            endDate={endDate}
            currencySign={currencySign}
            currentValue={currentValue}
            setShowEndDateModal={setShowEndDateModal}
            xAxisFirstLabel="Heute"
            xAxisLastLabel={endDate}
            autoHeight
            isMobile
            oppositeYLabels
            showFinabroModel
            showFirstPoint={false}
            showValueInLegend={false}
            disableMouseTracking
            xAxisGrid
            yAxisGrid
            labelsYEnabled
            yAxisLabelsInsideGrid
            startOnTick={false}
            endOnTick={false}
            produktmantel={produktmantel.id}
          />
        </ErrorBoundary>
      )}

      <div className="Forecast__legend">
        {produktmantel.id === 3 ? (
          <>
            <div
              className="Forecast__legendItem Forecast__legendItem--profit-sharing"
              onClick={() => setShowLegendHintModal('profit-sharing')}
            >
              <div className="Forecast__legendItemIcon"></div>
              Angenommene Wertsteigerung <img className="icon" src="assets/images/UI/icon_family_pfade_back.svg" />
            </div>

            <div
              className="Forecast__legendItem Forecast__legendItem--guaranteed"
              onClick={() => setShowLegendHintModal('guaranteed')}
            >
              <div className="Forecast__legendItemIcon"></div>
              Einbezahlte Nettoprämie <img className="icon" src="assets/images/UI/icon_family_pfade_back.svg" />
            </div>
          </>
        ) : (
          <>
            <div
              className="Forecast__legendItem Forecast__legendItem--average"
              onClick={() => setShowLegendHintModal('average')}
            >
              <div className="Forecast__legendItemIcon"></div>
              Durchschnittlich <img className="icon" src="assets/images/UI/icon_family_pfade_back.svg" />
            </div>

            <div
              className="Forecast__legendItem Forecast__legendItem--deposited"
              onClick={() => setShowLegendHintModal('deposited')}
            >
              <div className="Forecast__legendItemIcon"></div>
              Eingezahlt <img className="icon" src="assets/images/UI/icon_family_pfade_back.svg" />
            </div>

            <div
              className="Forecast__legendItem Forecast__legendItem--bad"
              onClick={() => setShowLegendHintModal('bad')}
            >
              <div className="Forecast__legendItemIcon"></div>
              Pessimistisch <img className="icon" src="assets/images/UI/icon_family_pfade_back.svg" />
            </div>
          </>
        )}
      </div>

      <Modal
        className="ForecastChart__endDateModal"
        overlayClassName="modalOverlay"
        // portalClassName={modalClassName ? modalClassName : undefined}
        contentLabel="Hint"
        isOpen={showEndDateModal}
        onRequestClose={() => setShowEndDateModal(false)}
      >
        <form>
          <label className={`RadioGroup__item ${endDate === '5 Jahre' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="endDate"
              value="5 Jahre"
              checked={endDate === '5 Jahre'}
              onChange={() => {
                setEndDate('5 Jahre')
                setShowEndDateModal(false)
              }}
            />
            5 Jahre
          </label>
          <label className={`RadioGroup__item ${endDate === '10 Jahre' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="endDate"
              value="10 Jahre"
              checked={endDate === '10 Jahre'}
              onChange={() => {
                setEndDate('10 Jahre')
                setShowEndDateModal(false)
              }}
            />
            10 Jahre
          </label>
          <label className={`RadioGroup__item ${endDate === 'Pensionsantritt' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="endDate"
              value="Pensionsantritt"
              checked={endDate === 'Pensionsantritt'}
              onChange={() => {
                setEndDate('Pensionsantritt')
                setShowEndDateModal(false)
              }}
            />
            Pensionsantritt
          </label>
        </form>
      </Modal>

      <Modal contentLabel="Hint" isOpen={showHintModal} onRequestClose={() => setShowHintModal(false)}>
        <p
          dangerouslySetInnerHTML={{
            __html: produktmantel.id === 3 ? resources.riskTextBAV : resources.riskText,
          }}
        />
      </Modal>

      <Modal contentLabel="Hint" isOpen={!!showLegendHintModal} onRequestClose={() => setShowLegendHintModal(false)}>
        {renderLegendHintModalText(showLegendHintModal)}
      </Modal>
    </div>
  )
}

export default Forecast

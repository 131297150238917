/* @flow */
'use strict'

import type { Action } from '../actions/types'
import type { Partner } from './main'

export type User = {
  id: number,
  kundennr: number,
  vorname: string,
  nachname: string,
  fullname: string,
  anrede: string,
  titel: string,
  titel2: string,
  geburtsDatum: string,
  geburtsLand: string,
  geburtsOrt: string,
  nationalitaet: string,
  familienStand: string,
  strasse: string,
  plz: number | string,
  ort: string,
  land: string,
  telefon: string,
  email: string,
  // status === 1 ('Signup abgebrochen')
  // status === 2 ('Dok an Kunde schicken')
  // status === 3 ('Dok bei Kunde')
  // status === 4 ('Dok bei Partner')
  // status === 5 ('Konto eröffnet')
  signupType: Object,
  status: number,
  benutzerstatus: Object,
  memberSince: string,
  lastLogin: string,
  // NOTE: bei jedem neuen Login wird zwischen 'FINABRO Modellrechnung'
  // und 'Gesetzlicher Modellrechnung' gewechselt
  showFinabroModel: boolean,
  konto?: UserKonto,
}

type UserKonto = {
  id: number,
  nummer: string,
  name: string,
  curr: string,
  institut: string,
  iban: string,
  bic: string,
}

export type Product = {
  id: number,
  name: string,
  start: string,
  ende: string,
  partner: Partner,
  mantel: string,
  produktmantel: Object,
  kategorie: string,
  produktkategorie: Object,
  portfoliorisiko: number,
  status: string,
  waehrung: string,
  zielSumme: number,
  zielRente: number,
  bewertungsdatum: string,
  gesamtwert: number,
  depotwert: number,
  kontostand: number,
  gewinn: number,
  rendite: number,
  sumEinzahlungen: number,
  musterPortfolio: Portfolio,
  konto: Konto,
  depot: Depot,
  allocation: Array<Allocation>,
  allocationGroup: Array<Allocation>,
  performance: Array<Performance>,
  sparplan: SavingsPlan,
  sparplaene: Array<SavingsPlan>,
  beneficiary?: Object,
}

export type Portfolio = {
  id: number,
  name: string,
  targetReturn: number,
  expNetReturn: number,
  expHighNetReturn: number,
  expLowNetReturn: number,
  expVolatility: number,
  managementFee: number,
  srri: Risiko,
  risk: Risiko,
}

export type Risiko = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

type Konto = {
  id: number,
  nummer: string,
  name: string,
  curr: string,
  iban: string,
  bic: string,
  transaktionen: Array<Transaction>,
}

type Transaction = {
  id: number,
  datum: string,
  valuta: string,
  typ: string,
  text: string,
  displaytext: string,
  betrag: number,
}

export type Depot = {
  id: number,
  nummer: string,
  name: string,
  curr: string,
  trades: Array<Trade>,
  positions: Array<Position>,
  valuationDate: string,
}

type Trade = {
  id: number,
  date: string,
  code: string,
  name: string,
  side: string,
  size: number,
  price: number,
}

export type Position = {
  code: string,
  name: string,
  klasse: string,
  kategorie: string,
  size: number,
  entry: number,
  price: number,
  curr: string,
  profit: number,
  value: number,
  valueEUR: number,
  alloc: number,
  url: string,
}

export type Allocation = {
  name: string,
  value: number,
  alloc: number,
}

export type Performance = Array<number>

export type SavingsPlan = {
  id: number,
  start: string,
  typ: string,
  typid: number,
  interval: number,
  betrag: number,
  wachstumsRate: number,
}

export type ScenarioData = {
  allocation: Array<Object>,
  allocDetails: Array<Object>,
  portfolio: string,
  srri: Risiko,
  typ: string,
  columns: Array<string>,
  series: Array<Array<number>>,
  sparplan: Object,
}

type State = {
  data: User,
  products: Array<Product>,
  hasLoggedOut: boolean,
}

const initialState: State = {
  data: {
    id: 0,
    vorname: '',
    nachname: '',
    fullname: '',
    anrede: '',
    titel: '',
    titel2: '',
    geburtsDatum: '',
    geburtsLand: '',
    geburtsOrt: '',
    nationalitaet: '',
    familienStand: '',
    strasse: '',
    plz: '',
    ort: '',
    land: '',
    telefon: '',
    email: '',
    memberSince: '',
    lastLogin: '',
    showFinabroModel: true,
    signupType: { id: 0 },
    konto: {
      id: 0,
      nummer: '',
      name: '',
      curr: '',
      institut: '',
      iban: '',
      bic: '',
    },
  },
  products: [],
  hasLoggedOut: false,
}

export default function userReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_INITIAL_USER':
      return fetchInitialUser(state, action)

    case 'LOGOUT':
      return logoutUser()

    case 'SET_HASLOGGEDOUT':
      return setHasLoggedOut(state, action)

    default:
      return state
  }
}

// reducers

function fetchInitialUser(state, action): State {
  const { userData, userProducts } = action.payload
  const fullname =
    userData.vorname && userData.nachname
      ? `${userData.vorname} ${userData.nachname}`
      : userData.user_vorname && userData.user_nachname
      ? `${userData.user_vorname} ${userData.user_nachname}`
      : ''

  return {
    ...state,
    data: {
      ...userData,
      fullname: fullname,
    },
    products: userProducts,
  }
}

function logoutUser(): State {
  return {
    ...initialState,
    hasLoggedOut: true,
  }
}

function setHasLoggedOut(state, action): State {
  return {
    ...state,
    hasLoggedOut: action.payload,
  }
}

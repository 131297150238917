// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.CardFooter {\n  background: #ffffff;\n  border-top: 1px solid #f5f9fc;\n  color: #8d9eaa;\n  position: absolute;\n  display: flex;\n  font-size: 0.6rem;\n  width: 100%;\n  height: 55px;\n  bottom: 0;\n  z-index: 3; }\n  .CardFooter__item {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n    height: 100%; }\n    .CardFooter__item.selected {\n      background: #32d69a;\n      color: #fff; }\n  .CardFooter__itemIcon {\n    fill: #0E3F60;\n    height: 25px;\n    width: 25px; }\n    .selected .CardFooter__itemIcon {\n      fill: #fff; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

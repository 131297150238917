/* @flow */
'use strict'

import React, { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import MessageService from 'services/api/message'
import date from 'utils/date'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import defaultResources from 'resources/default.json'

type Props = {
  setPostBoxNew?: () => void,
}

function Postbox({ setPostBoxNew }: Props) {
  const history = useHistory()
  // const dispatch = useDispatch()
  const [messages, setMessages] = useState([])
  // const messages = useSelector((state) => state.user.messages)
  const [messagesAreLoading, setMessagesAreLoading] = useState(false)
  // const [activeMessage, setActiveMessage] = useState({})
  // const [messageIsLoading, setMessageIsLoading] = useState(false)
  const [sortByMostRecent, setSortByMostRecent] = useState(true)

  const fetchMessages = async () => {
    setMessagesAreLoading(true)
    const response = await MessageService.list()
    const { messages } = response.data
    messages.sort((a, b) => (b.id > a.id ? 1 : -1))
    // dispatch(fetchUserMessages(messages))
    setMessages(messages)
    setPostBoxNew(messages.filter((el) => !el.readdate).length)
    setMessagesAreLoading(false)
  }

  const sortMessages = async () => {
    setMessagesAreLoading(true)
    const response = await MessageService.list()
    const { messages } = response.data

    if (sortByMostRecent) {
      messages.sort((a, b) => (b.id > a.id ? -1 : 1))
    } else {
      messages.sort((a, b) => (b.id > a.id ? 1 : -1))
    }

    // dispatch(fetchUserMessages(messages))
    setMessages(messages)
    setSortByMostRecent((prevState) => !prevState)
    setMessagesAreLoading(false)
  }

  // on mount
  useEffect(() => {
    fetchMessages()
  }, [])

  const resources = defaultResources.inside.notification

  return (
    <div className="Postbox Notification container infoBox">
      <div className="column bubble">
        <div className="Postbox__heading heading">
          <h2>{resources.title}</h2>
        </div>

        <div className="Postbox__actions">
          <div className={`messages__sort ${messagesAreLoading ? 'is-loading' : ''}`} onClick={() => sortMessages()}>
            {sortByMostRecent && 'Neueste zuerst'}
            {!sortByMostRecent && 'Älteste Zuerst'}
          </div>

          <div
            className={`messages__refresh ${messagesAreLoading ? 'is-loading' : ''}`}
            onClick={() => fetchMessages()}
          >
            Aktualisieren
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>

        <div className="items">
          {messagesAreLoading ? (
            <div className="Postbox__loading">
              <LoadingSpinner text="Nachrichten werden geladen..." />
            </div>
          ) : (
            <>
              {messages.length > 0 ? (
                <>
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={!message.readdate ? 'post new' : 'post'}
                      onClick={() => history.push(`/inside/message/${message.id}`)}
                    >
                      {!message.readdate && <span className="newIcon"></span>}
                      <div className="infoRow">
                        <span className="sender">{message.sender}</span>

                        {message.senddate && (
                          <span className="date">
                            {date.format(date.parse(message.senddate, 'dd.MM.yyyy HH:mm:ss'), 'dd.MM.yyyy')}
                          </span>
                        )}
                      </div>
                      <div className="title">{message.subject}</div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="Postbox__empty">Keine Nachrichten</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Postbox

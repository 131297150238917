/* @flow */
'use strict'

import React from 'react'
import { Link } from 'react-router-dom'

const NoMatch = () => {
  return (
    <div id="NoMatch" className="NoMatch container">
      <h2>Fehler 404</h2>
      <h1>Hoppla, diese Seite existiert leider nicht</h1>
      <p>
        <Link to="/">Zur Startseite</Link>
      </p>
    </div>
  )
}

export default NoMatch

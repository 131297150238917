import React from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { General, NoMatch, Login, Logout, PasswordReset } from './containers/General'
import { InsideArea } from './containers/InsideArea'

const AppRouter = () => (
  <HelmetProvider>
    <HashRouter>
      <Switch>
        {/* Inside Area */}
        <Route path="/inside" component={InsideArea} />

        {/* Auth */}
        <Route path="/login" render={() => <General><Login/></General>}/>
        <Route path="/logout" render={() => <General><Logout/></General>}/>
        <Route path="/passwort-vergessen" render={() => <General><PasswordReset/></General>}/>
        <Redirect exact from="/" to="/login" />

        {/* 404 */}
        <Route path="*" component={NoMatch} />
      </Switch>
    </HashRouter>
  </HelmetProvider>
)

export default AppRouter

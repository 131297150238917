import * as React from 'react'

function SvgIconAttachment(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path
        d="M324.07 897.92c-2.67 0-5.36-.06-8.09-.18-44.94-2-89.56-22.06-122.43-54.94-31.07-31.06-49.08-70.46-50.73-110.94-1.85-45.67 17.06-89.71 54.69-127.35 79.12-79.11 162-173.73 235.11-257.21C493.09 278.25 550.21 213 595.29 168c35.66-35.66 80.52-48.54 126.31-36.28 50.45 13.49 93.4 56.33 106.85 106.58 12 44.88-.47 88.71-35.17 123.41L408.83 746.16c-18.09 18.09-39.17 27.66-61 27.68h-.08a74.69 74.69 0 01-74.2-74.18c0-21.73 9.53-42.75 27.55-60.76l271.56-271.53 17 17-271.56 271.5c-13.42 13.42-20.52 28.57-20.52 43.8a50.69 50.69 0 0050.2 50.17h.06c15.3 0 30.52-7.15 44-20.65L776.3 344.74c28.39-28.39 38.68-64 29-100.23-11.32-42.24-47.44-78.26-89.88-89.63-37.16-10-73.79.71-103.13 30.06-44.51 44.51-101.38 109.44-161.59 178.17-73.36 83.76-156.51 178.7-236.18 258.38-32.77 32.76-49.26 70.6-47.69 109.4 1.4 34.43 16.93 68.15 43.72 94.94 56 56.06 152.69 72.91 228.94-3.34C602.53 659.42 841.7 415.16 844.1 412.71l17.15 16.79c-2.4 2.45-241.66 246.8-404.82 410-27.02 26.99-71.54 58.42-132.36 58.42z"
        fill="#1f4868"
      />
    </svg>
  )
}

export default SvgIconAttachment

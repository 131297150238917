import React from 'react'
import { useSelector } from 'react-redux'
import NavigationBar from 'components/UI/NavigationBar'
import { useRouteMatch } from 'react-router-dom'
import ProductHeader from 'components/ProductHeader'
import ProductContainer from 'components/ProductContainer'
import InformationTile from 'components/InformationTile'
import {
  IconBaselineWorkOutline,
  IconCalendarStart,
  IconCardAccountDetails,
  IconFinishFlag,
  IconLineGraph,
  IconMoney,
  IconMoneyHand,
  IconPeopleMoney,
  IconPeopleSafe,
  IconTimeFive,
} from '../../components/UI/SVG'

const navItems = [
  {
    name: ' Versicherung',
    path: '/inside/de/0/versicherung',
    icon: <IconPeopleSafe />,
  },
  {
    name: ' Arbeitgeberleistungen',
    path: '/inside/de/0/arbeitgeberleistungen',
    icon: <IconBaselineWorkOutline />,
  },
  {
    name: ' Vertragslaufzeit',
    path: '/inside/de/0/vertragslaufzeit',
    icon: <IconTimeFive></IconTimeFive>,
  },
]

export const ProductDetailsGermany = ({ resources, showInfo, showIndex, setShowInfo, setShowIndex }) => {
  //ToDo: use index of URL to support muliple products for one consumer
  const index = 0
  const userProducts = useSelector((state) => state.user.products)
  const { tab } = useRouteMatch().params
  const accessToken = localStorage.getItem('accessToken')
  const userProduct = userProducts[index]

  let ProductInformation = <></>
  if (tab === 'versicherung') {
    ProductInformation = (
      <>
        <InformationTile
          header="Versicherung"
          subHeader={userProduct?.productProvider.name}
          icon={<img src={`${userProduct?.productProvider?.downloadLinkLogo}&access_token=${accessToken}`} />}
        />
        <InformationTile header="Tarif" subHeader={userProduct?.product?.tariffName} icon={<IconLineGraph />} />
        <InformationTile header="Profil" subHeader={userProduct?.product?.profile} icon={<IconCardAccountDetails />} />
      </>
    )
  }
  if (tab === 'arbeitgeberleistungen') {
    ProductInformation = (
      <>
        {userProduct?.employerBonusPercentage && (
          <InformationTile
            header="Arbeitgeberzuschuss"
            subHeader={`${userProduct?.employerBonusPercentage?.toFixed(2)}%`}
            icon={<IconPeopleMoney />}
          />
        )}
        {userProduct?.employerContribution && (
          <InformationTile
            header="Arbeitgeberbeitrag"
            subHeader={`${userProduct?.employerContribution?.toFixed(2)}€`}
            icon={<IconMoneyHand />}
          />
        )}
        {userProduct?.capitalFormingBenefit && (
          <InformationTile
            header="Vermögenswirksame Leistungen"
            subHeader={`${userProduct?.capitalFormingBenefit?.toFixed(2)}€`}
            icon={<IconMoney />}
          />
        )}
      </>
    )
  }
  if (tab === 'vertragslaufzeit') {
    ProductInformation = (
      <>
        <InformationTile
          header="Vertragsbeginn"
          subHeader={userProduct?.sparplan?.start}
          icon={<IconCalendarStart />}
        />
        <InformationTile header="Rentenantritt" subHeader={userProduct?.sparplan?.ende} icon={<IconFinishFlag />} />
      </>
    )
  }

  return (
    <div className="Product container">
      <div className="bubble">
        <ProductHeader
          customName={userProduct?.customName}
          unternehmen={userProduct?.employer?.name}
          productProvider={userProducts[0]?.productProvider}
        />

        <ProductContainer>{ProductInformation}</ProductContainer>

        <NavigationBar items={navItems} />
      </div>
    </div>
  )
}

/* @flow */
'use strict'

import variables from '../../stylesheets/utils/variables.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logout, getCheckList } from 'core/api'
import { separateDecimalNumber } from 'core/helpers'
import { isBavProduct, isProductFLV, isProductKLV, isPartnerHelvetia } from 'utils/matrix'
import { logoutUser } from 'actions/user'
import CardFooter from 'components/UI/CardFooter'
import Modal from 'components/UI/Modal'
import IconBack from 'components/UI/SVG/IconBack'
import IconExternal from 'components/UI/SVG/IconExternal'
import defaultResources from 'resources/default.json'

type Props = {
  user: Object,
  userProducts: Object,
  setShowIndex?: (number) => void,
}

type State = {
  ress: Object,
  isDetail: boolean,
  popupText: string,
  checkList: Array<string>,
}

class Account extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      popupText: null,
      checkList: null,
    }

    this.onLogout = this.onLogout.bind(this)
    this.format = this.format.bind(this)
  }

  componentDidMount() {
    const isDetail = location.href.includes('details')
    const depth = !!this.props.match.params[0] ? this.props.match.params[0].split('/') : []
    let ress = isDetail ? defaultResources.inside.details : defaultResources.inside.account

    for (let i = 0; i < depth.length; i++) {
      if (!!ress.menu[depth[i]].menu) {
        ress = ress.menu[depth[i]]
      } else {
        ress = null
        break
      }
    }

    if (!!ress.checkLink) {
      getCheckList(ress.checkLink, (result) => {
        if (result.success) {
          this.setState({ checkList: result.data, isDetail, ress })
        }
      })
    } else {
      this.setState({ checkList: null, isDetail, ress })
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const isDetail = location.href.indexOf('details') >= 0

    if (this.props.match.params[0] !== nextProps.match.params[0] || isDetail !== this.state.isDetail) {
      const depth = !!nextProps.match.params[0] ? nextProps.match.params[0].split('/') : []
      let ress = isDetail ? nextProps.resources.details : nextProps.resources.account

      for (let i = 0; i < depth.length; i++) {
        if (!!ress.menu[depth[i]].menu) {
          ress = ress.menu[depth[i]]
        } else {
          ress = null
          break
        }
      }

      if (!!ress.checkLink) {
        getCheckList(ress.checkLink, (result) => {
          if (result.success) {
            this.setState({ checkList: result.data, isDetail, ress })
          }
        })
      } else {
        this.setState({ checkList: null, isDetail, ress })
      }
    }
  }

  render() {
    const { history } = this.props
    const { ress, isDetail, popupText, checkList } = this.state

    const productData = this.props.userProducts[0]
    const produktmantel = productData?.produktmantel.id
    const partner = productData?.partner.id
    const isBAVProduct = isBavProduct(this.props.user.signupType.id)

    if (!!ress) {
      const data = ress.tab?.indexOf('/einstellungen') >= 0 ? productData : this.props.user
      let filteredMenu = ress.menu

      if (isBAVProduct) {
        if (isProductFLV(produktmantel)) {
          filteredMenu = ress.menu.filter((entry) => entry.title !== 'Zielbetrag')
        }

        if (isProductKLV(produktmantel)) {
          filteredMenu = ress.menu.filter((entry) => entry.title !== 'Zielbetrag' && entry.title !== 'Portfolio')
        }
      }

      return (
        <div className={`${isDetail ? 'ProductDetails' : 'Account'} container infoBox`}>
          <div className="column bubble">
            <div className="heading">
              <h2>{ress.title}</h2>
            </div>

            <div className="items" style={{ height: isDetail ? 'calc(100% - 136px)' : 'calc(100% - 80px)' }}>
              {!!filteredMenu &&
                filteredMenu.map((menuRow, index) => {
                  const {
                    disabled,
                    title,
                    tab,
                    menu,
                    link,
                    doc,
                    backup,
                    func,
                    text,
                    value,
                    value2,
                    type,
                    external,
                    checkWord,
                    page,
                  } = menuRow

                  if (!disabled) {
                    if (!!value) {
                      let val = undefined,
                        val2 = undefined
                      if (type !== 10) {
                        val = data[value[0]]
                        for (let i = 1; i < value.length; i++) {
                          if (!!val) {
                            val = val[value[i]]
                          } else {
                            break
                          }
                        }
                        val2 = undefined
                        if (!!value2) {
                          val2 = data[value2[0]]
                          for (let i = 1; i < value2.length; i++) {
                            if (!!val2) {
                              val2 = val2[value2[i]]
                            } else {
                              break
                            }
                          }
                        }
                      } else {
                        val = ''
                        for (let i = 0; i < value.length; i++) {
                          let helper = data[value[i][0]]
                          for (let j = 1; j < value[i].length; j++) {
                            if (!!helper) {
                              helper = helper[value[i][j]]
                            } else {
                              break
                            }
                          }
                          val += helper + ' '
                        }
                      }
                      if (val) {
                        return (
                          <div className="item row" key={index}>
                            <div>{title}</div>
                            <div>{this.format(val, val2, type)}</div>
                          </div>
                        )
                      }
                    } else {
                      return (!!tab || !!link || !!doc || !!page) &&
                        (!checkWord || !checkList || checkList.indexOf(checkWord) >= 0 || !!backup) &&
                        (!title.includes('Geld einzahlen') || (!isBAVProduct && !isPartnerHelvetia(partner))) ? (
                        <div
                          key={index}
                          className={`item ${tab ? 'tab' : 'link'}`}
                          onClick={
                            !!tab
                              ? !!menu
                                ? () => location.assign(`${location.href}/${index}`)
                                : () => history.push(tab)
                              : !!link
                              ? external
                                ? !isBAVProduct
                                  ? () => window.open(link, '_system')
                                  : () => window.open('https://www.finabro.at/bav/faq/', '_system')
                                : () => window.open(link, '_system')
                              : !!page
                              ? () => history.push(page)
                              : !!checkWord
                              ? checkList.indexOf(checkWord) >= 0
                                ? () =>
                                    history.push(
                                      '/inside/pdfpage/' +
                                        encodeURIComponent(title) +
                                        '/' +
                                        type +
                                        '/' +
                                        encodeURIComponent(doc),
                                    )
                                : !!backup
                                ? () =>
                                    history.push(
                                      '/inside/pdfpage/' +
                                        encodeURIComponent(title) +
                                        '/0/' +
                                        encodeURIComponent(backup),
                                    )
                                : null
                              : () =>
                                  history.push(
                                    '/inside/pdfpage/' +
                                      encodeURIComponent(title) +
                                      '/' +
                                      type +
                                      '/' +
                                      encodeURIComponent(doc),
                                  )
                          }
                        >
                          {title}

                          {(menu || title.includes('Geld einzahlen')) && (
                            <IconBack
                              color={variables.primaryColor}
                              style={{
                                position: 'absolute',
                                right: '15px',
                                width: '20px',
                                height: '20px',
                                transform: 'rotate(180deg)',
                              }}
                            />
                          )}

                          {link && (
                            <IconExternal
                              color={variables.primaryColor}
                              style={{ position: 'absolute', right: '15px', width: '20px', height: '20px' }}
                            />
                          )}
                        </div>
                      ) : func === 'logout' ? (
                        <div key={index} className="item func" onClick={this.onLogout}>
                          {title}
                        </div>
                      ) : func === 'popup' ? (
                        <div key={index} className="item func" onClick={() => this.setState({ popupText: text })}>
                          {title}
                        </div>
                      ) : null
                    }
                  } else {
                    return null
                  }
                })}
            </div>

            {isDetail && (
              <CardFooter
                index={this.props.match.params.index}
                signupType={this.props.user.signup_type}
                produktmantel={produktmantel}
                partnerid={partner}
                resources={defaultResources.inside.cardFooter}
                setShowIndex={this.props.setShowIndex}
              />
            )}
          </div>

          <Modal
            className="modalContent"
            overlayClassName="modalOverlay"
            contentLabel="Hint"
            isOpen={!!popupText}
            onRequestClose={() => this.setState({ popupText: null })}
          >
            <p>
              {!!popupText &&
                popupText.map((textRow, textIx) => {
                  return (
                    <div key={textIx} style={{ padding: '5px 0' }} dangerouslySetInnerHTML={{ __html: textRow }}></div>
                  )
                })}
            </p>
          </Modal>
        </div>
      )
    } else {
      return <div></div>
      //history.push("/404")
    }
  }

  onLogout(): void {
    const { dispatch, history } = this.props

    logout(() => {
      dispatch(logoutUser())
      history.push('/logout')
    })
  }

  format(value: string | number | Array<Object>, value2: number, type: number): string {
    let currencySign, number
    switch (type) {
      case 1:
        currencySign = this.props.userProducts[0].konto.curr == 'EUR' ? '€' : '$'
        number = separateDecimalNumber(Number(value))
        return currencySign + ' ' + number.integer + ',' + number.decimalPlace
      case 2:
        return value ? 'Ja' : 'Nein'
      case 3:
        let returnHelper = ''
        if (!!value && value.length > 0) {
          returnHelper = 'Ja'
          for (let i = 0; i < value.length; i++) {
            returnHelper += ', ' + value[i].country
          }
        } else {
          returnHelper = 'Nein'
        }
        return returnHelper
      case 4:
        if (value) {
          currencySign = this.props.userProducts[0].konto.curr == 'EUR' ? '€' : '$'
          number = separateDecimalNumber(Number(value2))
          return 'Ja, ' + currencySign + ' ' + number.integer + ',' + number.decimalPlace
        } else {
          return 'Nein'
        }
      case 5:
        return value.substr(value.length - 4, 4)
      case 6:
        currencySign = this.props.userProducts[0].konto.curr == 'EUR' ? '€' : '$'
        number = separateDecimalNumber(Number(value))
        return currencySign + ' ' + number.integer + ',' + number.decimalPlace + ' / Monat'
      case 7:
        currencySign = this.props.userProducts[0].konto.curr == 'EUR' ? '€' : '$'
        number = separateDecimalNumber(Number(value))
        return currencySign + ' ' + number.integer
      case 8:
        currencySign = this.props.userProducts[0].konto.curr == 'EUR' ? '€' : '$'
        number = separateDecimalNumber(Number(value))
        return currencySign + ' ' + number.integer + ' / Monat'
      case 9:
        return value.substr(0, 4) + '...' + value.substr(value.length - 4)
      default:
        return value
    }
  }
}

const mapStateToProps = (state: Object) => {
  return {
    user: state.user.data,
    userProducts: state.user.products,
  }
}

export default connect(mapStateToProps)(withRouter(Account))

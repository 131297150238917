/* @flow */
'use strict'

import React from 'react'
import { Link } from 'react-router-dom'

import './Logout.scss'

const Logout = () => (
  <div className="Logout container">
    <div className="column">
      <img className="logo" src="assets/images/logo.png" />

      <div className="Logout__title">Sie haben sich erfolgreich abgemeldet.</div>

      <Link className="button" to="/">
        Zurück zum Login
      </Link>
    </div>
  </div>
)

export default Logout

/* @flow */
'use strict'

import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { separateDecimalNumber } from '../../../core/helpers'
import CardContainer from '../../UI/CardContainer'
import variables from '../../../stylesheets/utils/variables.scss'

type Props = {
  currencySign: string,
  transactions: Object,
  resources?: Object,
  setShowInfo?: (number, boolean) => void,
  setShowIndex?: (number) => void,
}

function TransactionsCard({ transactions, currencySign, resources, setShowInfo, setShowIndex }: Props) {
  // cloning to not reverse the actual transactions
  const sortedTransactions = JSON.parse(JSON.stringify(transactions)).reverse()
  const subMenuItems = [{ id: 'transactions', title: resources.transactions.title }]

  useEffect(() => {
    setShowIndex(subMenuItems[0].id)

    return () => {
      setShowInfo('transactions', false)
    }
  }, [])

  const innerTable = (
    <Table className="TransactionsTable">
      <TableBody>
        {sortedTransactions &&
          sortedTransactions.map((transaction) => {
            const { betrag, id, datum, displaytext, typ } = transaction
            const formattedValue = separateDecimalNumber(betrag)
            const shortDate = datum.substring(0, 6) + datum.substring(8, 10)

            return (
              <TableRow key={id} style={{ borderBottom: 'none', height: 40, color: variables.primaryColor }}>
                <TableCell
                  style={{
                    width: 70,
                    height: 'inherit',
                    textOverflow: 'unset',
                    padding: '1px 0 0 8px',
                    fontSize: '12px',
                    verticalAlign: 'top',
                  }}
                >
                  {shortDate}
                </TableCell>
                <TableCell
                  style={{
                    height: 'inherit',
                    padding: '1px 0 0 5px',
                    fontSize: '12px',
                    verticalAlign: 'top',
                  }}
                >
                  {typ} <div className="grey"> {displaytext}</div>
                </TableCell>
                <TableCell
                  className={betrag >= 0 ? 'green' : 'danger'}
                  style={{
                    height: 'inherit',
                    width: 90,
                    textOverflow: 'unset',
                    fontSize: '12px',
                    verticalAlign: 'top',
                    align: 'right',
                    textAlign: 'right',
                    padding: '1px 8px 0 0',
                  }}
                >
                  {`${currencySign} ${
                    betrag < 0 && formattedValue.integer == 0 ? `-${formattedValue.integer}` : formattedValue.integer
                  }`}
                  ,<span className="superscript">{formattedValue.decimalPlace}</span>
                </TableCell>
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )

  return (
    <CardContainer subMenuItems={subMenuItems} className="TransactionsCard" setShowIndex={setShowIndex}>
      <div
        className="cardSlide"
        onScroll={(event) => {
          // add CardHeader height (82px) to offsetHeight otherwise the user can't scroll up again
          if (event.target.scrollHeight > event.target.offsetHeight) {
            setShowInfo('transactions', event.target.scrollTop > 0 ? true : false)
          }
        }}
      >
        {innerTable}
      </div>
    </CardContainer>
  )
}

export default TransactionsCard

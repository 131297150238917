/* @flow */
'use strict'

import React, { Component } from 'react'

import LoadingSpinner from '../LoadingSpinner'

type Props = {
  disableSpinner?: boolean,
  loadWithTimeout?: boolean,
  label?: string,
}

type State = {
  visible: boolean,
}

class LoadingOverlay extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      visible: props.loadWithTimeout ? false : true,
    }
  }

  componentDidMount() {
    // show loading spinner only if it is not already unmounted after 1500 seconds
    // unmounting happens in parent component, for example when data loading finished
    if (this.props.loadWithTimeout) {
      this.loadingTimer = setTimeout(() => {
        this.setState({ visible: true })
      }, 1500)
    }
  }

  componentWillUnmount() {
    // clear timeout when unmounting
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer)
    }
  }

  render() {
    const { disableSpinner, label } = this.props

    return (
      <div className="LoadingOverlay">
        {this.state.visible ? (
          <div className="column-center">
            {!disableSpinner ? <LoadingSpinner /> : null}
            <p className="LoadingText">{label ? label : 'Ihre Daten werden geladen'}</p>
          </div>
        ) : null}
      </div>
    )
  }
}

export default LoadingOverlay

import * as React from 'react'

function SvgIconTransactions(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path d="M640 460H99.03l148.48-148.49 16.98 16.98L156.97 436H640v24zM776.49 712.49l-16.98-16.98L867.03 588H384v-24h540.97L776.49 712.49z" />
    </svg>
  )
}

export default SvgIconTransactions

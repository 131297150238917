/* @flow */
'use strict'

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import IconBack from 'components/UI/SVG/IconBack'
import IconExternal from 'components/UI/SVG/IconExternal'
import defaultResources from 'resources/default.json'
import variables from '../../stylesheets/utils/variables.scss'

function Service() {
  const history = useHistory()
  const match = useRouteMatch()
  const userData = useSelector((state) => state.user.data)
  const [resources, setResources] = useState(null)

  useEffect(() => {
    let resources = defaultResources.inside.service
    const depth = match.params[0] ? match.params[0].split('/') : []

    for (let i = 0; i < depth.length; i++) {
      if (!!resources.menu[depth[i]].menu) {
        resources = resources.menu[depth[i]]
      } else {
        resources = null
        break
      }
    }

    setResources(resources)
  })

  const isBAVProduct = userData.signupType.id === 1

  if (!!resources) {
    return (
      <div className="Service container infoBox">
        <div className="column bubble">
          <div className="heading">
            <h2>{resources.title}</h2>
          </div>

          <div className="items" style={{ height: 'calc(100% - 80px)' }}>
            {!!resources.menu &&
              resources.menu.map((menuRow, index) => {
                const { title, tab, menu, link, external } = menuRow

                return (
                  (tab || link) && (
                    <div
                      key={index}
                      className={`${tab ? 'tab' : 'link'} ${menu ? 'menu' : ''}`}
                      onClick={
                        tab
                          ? menu
                            ? () => location.assign(`${location.href}/${index}`)
                            : () => history.push(tab)
                          : link
                          ? external
                            ? title.indexOf('FAQ') < 0 || !isBAVProduct
                              ? () => window.open(link, '_system')
                              : () => window.open('https://www.finabro.at/bav/faq/', '_system')
                            : () => window.open(link, '_system')
                          : null
                      }
                    >
                      {title}

                      {menu && (
                        <IconBack
                          color={variables.primaryColor}
                          style={{
                            position: 'absolute',
                            right: '15px',
                            width: '20px',
                            height: '20px',
                            transform: 'rotate(180deg)',
                          }}
                        />
                      )}

                      {link && (
                        <IconExternal
                          color={variables.primaryColor}
                          style={{ position: 'absolute', right: '15px', width: '20px', height: '20px' }}
                        />
                      )}
                    </div>
                  )
                )
              })}
          </div>
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default Service

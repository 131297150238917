/* @flow */
'use strict'

import React, { useState, useEffect } from 'react'
import { findIndex, last, sortBy } from 'lodash'
import sub from 'date-fns/sub'

import HistoryChart from 'components/Charts/HistoryChart'
import Modal from 'components/UI/Modal'
import ErrorBoundary from 'containers/General/ErrorBoundary'

import type { Performance } from '../../../reducers/User'

type Props = {
  currencySign: string,
  performance: Array<Performance>,
  resources?: Object,
}

function History({ performance, currencySign }): Props {
  const [maxYValue, setMaxYValue] = useState(0)
  const [startDate, setStartDate] = useState('Max.')
  const [showDateModal, setShowDateModal] = useState(false)

  useEffect(() => {
    const sortedPerformance = sortBy(performance, (axisData) => axisData[1])

    if (sortedPerformance.length > 0) {
      setMaxYValue(last(sortedPerformance)[1])
    }
  }, [])

  const now = new Date()
  let startDateTimestamp
  switch (startDate) {
    case 'Jahresbeginn':
      startDateTimestamp = new Date(now.getFullYear(), 0, 1)
      break
    case '3 Monate':
      startDateTimestamp = sub(now, {
        months: 3,
      })
      break
    case '6 Monate':
      startDateTimestamp = sub(now, {
        months: 6,
      })
      break
    case '1 Jahr':
      startDateTimestamp = sub(now, {
        years: 1,
      })
      break
    case 'Max.':
    default:
      startDateTimestamp = sub(now, {
        years: 10,
      })
      break
  }

  const filteredPerformance = performance.filter(([x]) => x >= Number(startDateTimestamp))

  // const minIndex = findIndex(performance, (axisData) => axisData[1] >= 0)

  let type = 1
  // const startDate = new Date(performance[minIndex > 0 ? minIndex - 1 : minIndex][0])
  // const startDate = new Date(performance[performance.length - 1][0])
  // startDate.setMonth(startDate.getMonth() + 5)
  // if (startDate.getTime() < startDate.getTime()) {
  //   type = 2
  //   startDate.setMonth(startDate.getMonth() + 7)
  //   if (startDate.getTime() < startDate.getTime()) {
  //     type = 3
  //   }
  // }

  // let perf = JSON.parse(JSON.stringify(performance))
  // if (isBAVProduct) {
  //   while (perf[0][1] == 0) {
  //     perf.shift()
  //   }
  // }

  return (
    <div className="History chartContainer">
      <ErrorBoundary errorText="Fehler beim Zeichnen des Charts.">
        <HistoryChart
          performance={filteredPerformance}
          currencySign={currencySign}
          disableMarker
          disableTooltip
          enableMouseTracking={false}
          autoHeight
          oppositeYLabels
          maxYValue={maxYValue}
          useMinX
          useMinY
          labelsXEnabled
          labelsYEnabled
          // startOnTickY={false}
          // endOnTickY={false}
          xAxisGrid
          yAxisGrid
          yAxisLabelsInsideGrid
          formatType={type}
        />
      </ErrorBoundary>

      <div className="History__labels">
        <div className="History__dateToggle" onClick={() => setShowDateModal(true)}>
          Zeitraum auswählen
        </div>
      </div>

      <Modal
        className="History__startDateModal"
        overlayClassName="modalOverlay"
        // portalClassName={modalClassName ? modalClassName : undefined}
        contentLabel="Hint"
        isOpen={showDateModal}
        onRequestClose={() => setShowDateModal(false)}
      >
        <form>
          <label className={`RadioGroup__item ${startDate === 'Jahresbeginn' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="startDate"
              value="Jahresbeginn"
              checked={startDate === 'Jahresbeginn'}
              onChange={() => {
                setStartDate('Jahresbeginn')
                setShowDateModal(false)
              }}
            />
            Seit Jahresbeginn
          </label>
          <label className={`RadioGroup__item ${startDate === '3 Monate' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="startDate"
              value="3 Monate"
              checked={startDate === '3 Monate'}
              onChange={() => {
                setStartDate('3 Monate')
                setShowDateModal(false)
              }}
            />
            3 Monate
          </label>
          <label className={`RadioGroup__item ${startDate === '6 Monate' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="startDate"
              value="6 Monate"
              checked={startDate === '6 Monate'}
              onChange={() => {
                setStartDate('6 Monate')
                setShowDateModal(false)
              }}
            />
            6 Monate
          </label>
          <label className={`RadioGroup__item ${startDate === '1 Jahr' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="startDate"
              value="1 Jahr"
              checked={startDate === '1 Jahr'}
              onChange={() => {
                setStartDate('1 Jahr')
                setShowDateModal(false)
              }}
            />
            1 Jahr
          </label>
          <label className={`RadioGroup__item ${startDate === 'Max.' ? 'RadioGroup__item--checked' : ''}`}>
            <input
              type="radio"
              name="startDate"
              value="Max."
              checked={startDate === 'Max.'}
              onChange={() => {
                setStartDate('Max.')
                setShowDateModal(false)
              }}
            />
            Max.
          </label>
        </form>
      </Modal>
    </div>
  )
}

export default History

import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'

type InformationTileProps = {
  header: string,
  subHeader?: string,
  icon: ReactNode,
}

const InformationTile = ({ header, subHeader, icon }) => {
  return (
    <div className="InformationTile">
      <Box
        className="InformationTile__content"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box className="InformationTile__icon" display="flex" alignItems="center" justifyContent="center">
          {icon}
        </Box>
        <Box display="flex" flexDirection="column">
          <span className="bold">{header}</span>
          {subHeader && <div className="label">{subHeader}</div>}
        </Box>
      </Box>
    </div>
  )
}

export default InformationTile

/* @flow */
'use strict'

import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // logErrorToMyService(error, info);
  }

  render() {
    const { errorText } = this.props

    if (this.state.hasError) {
      if (errorText) {
        return <p>{errorText}</p>
      } else {
        return <p>Es ist ein Fehler aufgetreten.</p>
      }
    }

    return this.props.children
  }
}

export default ErrorBoundary

'use strict'

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import moment from 'moment'

import { isBavProduct, isProductDepot, isPartnerPlattform } from 'utils/matrix'
import { separateDecimalNumber } from 'core/helpers'
import HistoryChart from 'components/Charts/HistoryChart'
import ZurichLogo from 'components/UI/SVG/ZurichLogoNotext'

type Props = {
  resources?: Object,
}

function disableBack(event) {
  event.preventDefault()
}

function Dashboard({ resources }: Props) {
  const history = useHistory()
  const userData = useSelector((state) => state.user.data)
  const userProducts = useSelector((state) => state.user.products)
  const accessToken = localStorage.getItem('accessToken')

  useEffect(() => {
    // Fix iPhone bottom cutout background color
    document.body.classList.add('is-Dashboard')

    document.addEventListener('backbutton', disableBack)

    return () => {
      document.body.classList.remove('is-Dashboard')
      document.removeEventListener('backbutton', disableBack)
    }
  }, [])

  const SliderSettings = {
    dots: userProducts.length > 1,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    className: 'Slider',
    centerPadding: '12.5%',
  }

  return (
    <div className="Dashboard">
      <Slider {...SliderSettings}>
        {userProducts.map((product, pIx) => {
          const { waehrung, name, produktkategorie, gesamtwert, performance, depot, partner, sumEinzahlungen } = product
          const { integer, decimalPlace } = separateDecimalNumber(gesamtwert)
          const currencySign = waehrung === 'USD' ? '$' : '€'
          const hasEinzahlung = sumEinzahlungen > 0

          if (isBavProduct(userData.signupType.id)) {
            while (performance.length > 0 && performance[0][1] === 0) {
              performance.shift()
            }
          }

          if (product?.productProvider?.countryIso3 === 'DEU' && accessToken) {
            return (
              <div className="CardChoice" key={pIx} onClick={() => history.push(`/inside/de/${pIx}/versicherung`)}>
                <div className="bubble">
                  <div className="CardChoiceImage">
                    <div className="categoryImage">
                      <img
                        className="productProviderLogo"
                        src={`${product?.productProvider?.downloadLinkLogo}&access_token=${accessToken}`}
                      />
                    </div>

                    <div className="categoryName">
                      <div className="categoryDiv">
                        <h2>{name}</h2>
                        <div className="xs">{product?.employer?.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          return (
            <div
              className="CardChoice"
              key={pIx}
              onClick={() => (hasEinzahlung ? history.push(`/inside/${pIx}/product/`) : null)}
            >
              <div className="bubble">
                <div className="CardChoiceImage">
                  <div className="categoryImage">
                    {userData.signupType.id === 1 && partner.id === 25 ? (
                      <ZurichLogo />
                    ) : produktkategorie.id === 1 ? (
                      <img src="assets/images/categories/pension-icon.png" />
                    ) : produktkategorie.id === 2 ? (
                      <img src="assets/images/categories/anlage-icon.png" />
                    ) : produktkategorie.id === 3 ? (
                      <img src="assets/images/categories/kinder-icon.png" />
                    ) : (
                      <img src="assets/images/categories/sparen-icon.png" />
                    )}
                  </div>

                  <div className="categoryName">
                    <div className="categoryDiv">
                      <h2>{name}</h2>
                      {isBavProduct(userData.signupType.id) ? <div className="xs">{userData.unternehmen}</div> : null}
                    </div>
                  </div>
                </div>

                {!hasEinzahlung && (
                  <p className="CardChoice__noDeposit">
                    Sobald die erste Einzahlung erfolgt ist, können Sie hier Ihr Produkt einsehen. Die Einzahlung
                    erfolgt automatisch, Sie brauchen nichts weiter zu tun.
                  </p>
                )}

                {hasEinzahlung && (
                  <>
                    <div className="info row-around">
                      <div>
                        <span className="label">{resources.cardHeader.value}</span>
                        <h2 style={{ marginBottom: depot && depot.valuationDate ? 0 : 9, textAlign: 'center' }}>
                          {currencySign} {integer},<span className="superscript">{decimalPlace}</span>
                        </h2>
                      </div>

                      {isProductDepot(product.produktmantel.id) &&
                        isPartnerPlattform(product.partner.id) &&
                        depot?.valuationDate && (
                          <p className="valuationDate xs">
                            {resources.cardHeader.valuationDate.text}:{' '}
                            {moment(depot.valuationDate, 'DD.MM.YYYY').format(
                              resources.cardHeader.valuationDate.format,
                            )}
                          </p>
                        )}
                    </div>

                    <div className="HistoryDiagram">
                      <HistoryChart
                        autoHeight
                        currencySign={currencySign}
                        performance={performance}
                        labelsXEnabled={false}
                        enableMouseTracking={false}
                        disableTooltip
                        disableMarker
                        maxPadding={0}
                        lineWidthX={0}
                        useMinX
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default Dashboard

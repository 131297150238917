import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { de } from 'date-fns/locale'

// Set default locale to german for date-fns functions

function parseDate(date, formatString = 'Ppp') {
  return parse(date, formatString, new Date(), { locale: de })
}

function formatDate(date, formatString = 'dd.MM.yyyy') {
  return format(date, formatString, { locale: de })
}

export default {
  parse: parseDate,
  format: formatDate,
}

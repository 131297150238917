'use strict'

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import FastClick from 'fastclick'

import { login } from 'core/api'
import { fetchAppDataUser } from 'actions/main'
import { fetchInitialUser, setHasLoggedOut } from 'actions/user'

import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import defaultResource from 'resources/default.json'

import type { Dispatch } from 'actions/types'
import type { User, Product } from 'reducers/user'

const clientId = 'FinabroApp'

function disableBack(event) {
  event.preventDefault()
}

function Login() {
  const dispatch = useDispatch()
  const history = useHistory()
  const hasLoggedOut = useSelector((state) => state.user.hasLoggedOut)
  const [email, setEmail] = useState(localStorage.getItem('user_email'))
  const [password, setPassword] = useState(null)
  const [error, setError] = useState(null)
  const [loginDisabled, setLoginDisabled] = useState(true)
  const [hideLoginLinks, setHideLoginLinks] = useState(false)
  const [deviceSupportsTouchId, setDeviceSupportsTouchId] = useState(false)
  const [touchIdPasswordExists, setTouchIdPasswordExists] = useState(false)

  const currentUser = localStorage.getItem('user_vorname')
  const resources = defaultResource.general.login

  let secureStorage = useRef(null)

  useEffect(() => {
    // TODO: useRef
    // FastClick.attach(document.getElementById('submitButton'))
    // FastClick.attach(document.getElementById('switchUserLink'))

    document.addEventListener('backbutton', disableBack)

    return () => {
      document.removeEventListener('backbutton', disableBack)
    }
  }, [])

  useEffect(() => {
    if (window.cordova && !secureStorage.current) {
      console.log('initializing secure storage...')
      secureStorage.current = new cordova.plugins.SecureStorage(
        () => console.log('initialized secure storage'),
        (error) => console.log('Error initializing secure storage: ' + error),
        clientId,
      )
    }

    let pluginTouchIdIntervalCount = 0
    const pluginTouchIdIntervalMaxTries = 5
    const pluginTouchIdIntervalMs = 500

    let helper = true

    const pluginTouchIdInterval = setInterval(() => {
      console.log('checking for biometric support...')
      deviceHasTouch(
        (result) => {
          clearInterval(pluginTouchIdInterval)
          setDeviceSupportsTouchId(true)

          if (email) {
            // check for existing biometric
            console.log('checking for existing biometric...')

            secureStorage.current.get(
              () => {
                console.log('got touch id from storage')
                setTouchIdPasswordExists(true)

                if (!hasLoggedOut) {
                  loginWithTouch()
                  helper = false
                }
              },
              () => {
                console.log('no touch id in storage')
                setTouchIdPasswordExists(false)
              },
              email,
            )
          }
        },
        (error) => console.error('deviceHasTouch Error:', error),
      )

      if (pluginTouchIdIntervalCount === pluginTouchIdIntervalMaxTries) {
        clearInterval(pluginTouchIdInterval)

        if (helper) {
          dispatch(setHasLoggedOut(false))
        }
      }
      pluginTouchIdIntervalCount++
    }, pluginTouchIdIntervalMs)

    return () => {}
  }, [])

  function onSubmit(event: Object): void {
    if (!deviceSupportsTouchId) {
      if (email && password) {
        setError(null)
        login(email, password, onLogin)
      } else {
        setError(!localStorage.getItem('user_email') ? resources.emptyError : resources.passwordError)
        setLoginDisabled(true)
      }
    } else if (touchIdPasswordExists && !password) {
      loginWithTouch()
    } else if (email && password) {
      login(email, password, (data: Object) => {
        if (data.success) {
          deviceOnSavePassword(
            email,
            password,
            (result) => {
              secureStorage.current.set(
                () => onLogin(data),
                (error) => onLogin(data),
                email,
                result,
              )
            },
            (error) => onLogin(data),
          )
        } else {
          setError(resources.credentialsError)
        }
      })
    } else {
      setError(!localStorage.getItem('user_email') ? resources.emptyError : resources.passwordError)
      setLoginDisabled(true)
    }
  }

  function onLogin(data: Object): void {
    if (data.success) {
      const { userData, userProducts, partner, productCategories } = data
      if (userData.accessToken) {
        localStorage.setItem('accessToken', userData.accessToken)
      }
      dispatch(fetchInitialUser(userData, userProducts))
      dispatch(fetchAppDataUser(partner, productCategories))

      localStorage.setItem('user_email', userData.email)
      localStorage.setItem('user_vorname', userData.vorname)
      history.push('inside')
    } else {
      setError(data.errDescription)
    }
  }

  function resetUser(): void {
    localStorage.removeItem('user_vorname')
    localStorage.removeItem('user_email')
    setEmail(null)
  }

  function loginWithTouch(data?: Object): void {
    deviceOnVerify(
      email,
      (password: string) => {
        login(email, password, onLogin)
      },
      (errorCode: string) => {
        if (errorCode !== 'FINGERPRINT_CANCELLED' && errorCode !== '-2') {
          window.plugins.touchid.delete(email, () => {
            setTouchIdPasswordExists(false)
          })
        }
      },
    )
  }

  function deviceHasTouch(callback: (data: Object) => void, error: (data: Object) => void): void {
    if (window.cordova?.platformId === 'ios') {
      if (window.plugins?.touchid) {
        window.plugins.touchid.isAvailable(callback, error)
      } else {
        error()
      }
    }

    if (window.cordova?.platformId === 'android') {
      FingerprintAuth.isAvailable(callback, error)
    }
  }

  function deviceOnSavePassword(
    email: string,
    password: string,
    callback: (data: Object) => void,
    error: (data: Object) => void,
  ): void {
    if (window.cordova?.platformId === 'ios') {
      window.plugins.touchid.verifyFingerprint('Aktivieren Sie hiermit TouchID/FaceID', () => callback(password), error)
    }

    if (window.cordova?.platformId === 'android') {
      FingerprintAuth.encrypt(
        {
          clientId,
          username: email,
          password,
          disableBack: true,
          dialogMessage: 'Aktivieren Sie hiermit Fingerprint Authentication',
        },
        (result) => callback(result.token),
        error,
      )
    }
  }

  function deviceOnVerify(email, callback: (data: Object) => void, error: (data: Object) => void): void {
    secureStorage.current &&
      secureStorage.current.get(
        (res) => {
          if (window.cordova?.platformId === 'ios') {
            window.plugins.touchid.verifyFingerprint(
              'Melden Sie sich mit TouchID/FaceID an',
              () => callback(res),
              error,
            )
          }

          if (window.cordova?.platformId === 'android') {
            FingerprintAuth.decrypt(
              {
                clientId,
                username: email,
                token: res,
                disableBack: true,
                dialogMessage: 'Melden Sie sich mit Ihrem Fingerprint an',
              },
              (result) => callback(result.password),
              error,
            )
          }
        },
        error,
        email,
      )
  }

  return (
    <div className="Login container">
      <div className={!currentUser ? 'column removeOnSmall' : 'column'}>
        <img className="logo" src="assets/images/logo.png" />

        {!!currentUser ? (
          <h1>
            {resources.namePrefix}
            {currentUser}
          </h1>
        ) : (
          <h1>{resources.title}</h1>
        )}

        <div className={window.cordova?.platformId === 'ios' ? 'credentials ios' : 'credentials'}>
          {!!currentUser ? null : (
            <Input
              id="email"
              type="email"
              autoComplete="email"
              floatingLabelText={resources.input1.label}
              hintText={resources.input1.hint}
              maxLength={50}
              tabIndex={1}
              value={email ? email : ''}
              style={{ fontSize: '0.9rem' }}
              onChange={(event: Object, email: string) => {
                setEmail(email)
                setLoginDisabled(!(email && password))
              }}
              onSubmit={onSubmit}
            />
          )}

          <Input
            id="password"
            type="password"
            placeholder={resources.input2.label}
            floatingLabelText={resources.input2.label}
            hintText={resources.input2.hint}
            linkPost={!password ? '/passwort-vergessen' : null}
            linkPostText={!password ? resources.forgotPassword : null}
            maxLength={50}
            style={{ fontSize: '0.9rem' }}
            tabIndex={2}
            value={password ? password : ''}
            onFocus={() => {
              setHideLoginLinks(true)
            }}
            onBlur={() => {
              setHideLoginLinks(false)
            }}
            onChange={(event: Object, password: string) => {
              setPassword(password)
              setLoginDisabled(!(email && password))
            }}
            onSubmit={onSubmit}
          />

          {!!error && <p className="error" dangerouslySetInnerHTML={{ __html: error }}></p>}

          <Button id="submitButton" name={resources.button.text2} tabIndex={3} onClick={onSubmit} />

          {!!currentUser && !hideLoginLinks && (
            <div id="switchUserLink" className="loginLinks bottom" onClick={resetUser}>
              {resources.resetUser}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Login

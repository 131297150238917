/* @flow */
'use strict'

import { isIos } from './helpers'

const SLIDER_SETTINGS = {
  infinite: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  centerPadding: '0',
  initialSlide: 0,
  fade: isIos ? false : true,
  touchMove: false,
}

export default SLIDER_SETTINGS

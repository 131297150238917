/* @flow */
'use strict'

import React, { Component } from 'react'
import { findIndex, forEach } from 'lodash'
import ReactHighcharts from 'react-highcharts'

import HIGHCHARTS_CONFIG from '../../../core/highcharts'
import { formatValue } from '../../../core/helpers'

import type { Performance } from '../../../reducers/User'
import dateFormat from 'dateformat'

import variables from '../../../stylesheets/utils/variables.scss'

type Props = {
  currencySign: string,
  enableMouseTracking?: boolean,
  height?: number,
  autoHeight?: boolean,
  labelsXEnabled?: boolean,
  labelsYEnabled?: boolean,
  lineWidthX?: number,
  oppositeYLabels?: boolean,
  performance: Array<Performance>,
  maxPadding?: number,
  maxYValue?: number,
  showAllXLabels?: boolean,
  scaleBy?: number,
  // use the lowest Y value as minimum value for y Axis
  useMinY?: boolean,
  // search for the first Y value and use corresponding x value
  // as minimum value for x Axis
  useMinX?: boolean,
  xAxisFirstLabel?: string,
  xAxisLastLabel?: string,
  disableTooltip?: boolean,
  disableMarker?: boolean,
  xAxisGrid?: boolean,
  yAxisGrid?: boolean,
  startOnTick?: boolean,
  endOnTick?: boolean,
  startOnTickY?: boolean,
  endOnTickY?: boolean,
  yAxisLabelsInsideGrid?: boolean,
  formatType?: Number,
}

type State = {
  dataMinX: number,
  dataMinY: number,
  scaledPerformance: Array<?*>,
}

class HistoryChart extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      dataMinX: 0,
      dataMinY: 0,
      scaledPerformance: [],
    }
  }

  componentDidMount() {
    const { performance, scaleBy, useMinX, useMinY } = this.props

    // NOTE: this has to be first
    if (useMinY) {
      const chart = this.refs.chart.getChart()
      this.setState({
        ...this.state,
        dataMinY: chart.yAxis[0].series[0].dataMin,
      })
    }

    // find first x value w/ corresponding positive y value
    if (useMinX) {
      const minIndex = performance.findIndex((axisData) => axisData[1] > 0)
      const axisDataMin = performance[minIndex]
      this.setState({
        ...this.state,
        dataMinX: axisDataMin ? axisDataMin[0] : 0,
      })
    }

    // divide axis data by stated value
    if (scaleBy) {
      const scaledPerformance = []
      forEach(performance, (axisData) => {
        const scaledAxisData = [axisData[0], axisData[1] / scaleBy]
        scaledPerformance.push(scaledAxisData)
      })
      this.setState({ scaledPerformance })
    }

    ReactHighcharts.Highcharts.setOptions({
      lang: {
        months: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
      },
    })

    dateFormat.i18n = {
      ...dateFormat.i18n,
      monthNames: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { performance, scaleBy, useMinX, useMinY } = this.props

    if (prevProps.performance !== this.props.performance) {
      // NOTE: this has to be first
      if (useMinY) {
        const chart = this.refs.chart.getChart()
        this.setState({
          ...this.state,
          dataMinY: chart.yAxis[0].series[0].dataMin,
        })
      }

      // find first x value w/ corresponding positive y value
      if (useMinX) {
        const minIndex = performance.findIndex((axisData) => axisData[1] > 0)
        const axisDataMin = performance[minIndex]
          this.setState({
            ...this.state,
            dataMinX: axisDataMin ? axisDataMin[0] : 0,
          })
      }

      if (scaleBy) {
        const scaledPerformance = performance.map((axisData) => [axisData[0], axisData[1] / scaleBy])
        this.setState({ scaledPerformance })
      }
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // NOTE: only update component if performance data from props
    // or local state change – this is a performance improvement,
    // as this prevents unnecessary rerenders of this component
    // if parent component rerenders
    return nextProps.performance !== this.props.performance || nextState !== this.state
  }

  render() {
    const {
      currencySign,
      enableMouseTracking,
      height,
      autoHeight,
      labelsXEnabled,
      labelsYEnabled,
      lineWidthX,
      oppositeYLabels,
      performance,
      maxPadding,
      maxYValue,
      scaleBy,
      showAllXLabels,
      useMinX,
      useMinY,
      xAxisFirstLabel,
      xAxisLastLabel,
      disableTooltip,
      disableMarker,
      xAxisGrid,
      yAxisGrid,
      startOnTick,
      endOnTick,
      startOnTickY,
      endOnTickY,
      yAxisLabelsInsideGrid,
      formatType,
    } = this.props
    const { dataMinX, dataMinY, scaledPerformance } = this.state

    let yearIndex = 0
    const xAxisLabels = !showAllXLabels
      ? {
          ...HIGHCHARTS_CONFIG.xAxis.labels,
          useHTML: true,
        }
      : {
          ...HIGHCHARTS_CONFIG.xAxis.labels,
          useHTML: true,
          formatter: function () {
            if (this.isFirst) {
              yearIndex = 0
              return yearIndex
            } else {
              yearIndex++
              return yearIndex
            }
          },
        }

    const diagramHeigth = height || 200
    let highChartsConfig = {
      ...HIGHCHARTS_CONFIG.basic,
      chart: {
        ...HIGHCHARTS_CONFIG.chart,
        height: !autoHeight ? diagramHeigth : null,
        spacing: !labelsXEnabled ? [0, 0, 0, 0] : [10, 10, 15, 10],
      },
      plotOptions: {
        area: {
          animation: false,
          events: {
            // make tooltip and marker at last point always visible after initial animation
            afterAnimate: function () {
              if (enableMouseTracking !== false) {
                const points = this.points
                const lastPoint = points[points.length - 1]
                lastPoint.setState('hover')
                if (!disableTooltip) {
                  this.chart.tooltip.refresh(lastPoint)
                }
              }
            },
          },
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, variables.chartColor],
              [1, '#fff'],
            ],
          },
          lineColor: variables.chartLineColor3,
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
        },
      },
      tooltip: !disableTooltip
        ? {
            ...HIGHCHARTS_CONFIG.tooltip,
            formatter: function () {
              return `${currencySign}${formatValue(this.y)}`
            },
          }
        : '',
      xAxis: {
        ...HIGHCHARTS_CONFIG.xAxis,
        gridLineWidth: !!xAxisGrid ? 1 : 0,
        gridLineColor: variables.chartGridColor,
        dateTimeLabelFormats: {
          month: '<div class="xAxis-month">%B</div><div class="xAxis-year">%Y</div>',
        },
        labels: {
          ...xAxisLabels,
          formatter: !!formatType
            ? function () {
                switch (formatType) {
                  case 1:
                    return dateFormat(new Date(this.value), 'd.m.')
                    break
                  case 2:
                    return dateFormat(new Date(this.value), 'mmm')
                    break
                  case 3:
                    return dateFormat(new Date(this.value), 'mmm yyyy')
                    break
                  default:
                    return this.value
                }
              }
            : null,
        },
        min: useMinX ? dataMinX : null,
        startOnTick: !!startOnTick,
        endOnTick: !!endOnTick ? endOnTick : showAllXLabels ? true : false,
        tickPositioner: !!formatType
          ? function () {
              let step = (this.dataMax - dataMinX) / 4
              return [dataMinX, dataMinX + step, dataMinX + step * 2, dataMinX + step * 3, this.dataMax]
            }
          : null,
        type: 'datetime',
        visible: !labelsXEnabled === false,
      },
      yAxis: {
        ...HIGHCHARTS_CONFIG.yAxis,
        gridLineWidth: !!yAxisGrid ? 1 : 0,
        gridLineColor: '#f6f6f6',
        opposite: oppositeYLabels ? oppositeYLabels : false,
        labels: labelsYEnabled
          ? {
              enabled: true,
              formatter: function () {
                return `${currencySign}${formatValue(this.value)}`
              },
              reserveSpace: yAxisLabelsInsideGrid ? false : null,
              align: yAxisLabelsInsideGrid ? 'right' : 'left',
              x: yAxisLabelsInsideGrid ? 0 : 10,
              y: yAxisLabelsInsideGrid ? -5 : undefined,
            }
          : { enabled: false },
        maxPadding: maxPadding !== undefined ? maxPadding : 0.3,
        min: useMinY && scaleBy ? dataMinY / scaleBy : useMinY ? dataMinY : null,
        max: maxYValue && scaleBy ? maxYValue / scaleBy : maxYValue ? maxYValue : null,
        startOnTick: startOnTickY !== undefined ? startOnTickY : true,
        endOnTick: endOnTickY !== undefined ? endOnTickY : true,
        lineWidth: lineWidthX !== undefined ? lineWidthX : undefined,
      },
      series: [
        {
          type: 'area',
          color: 'transparent',
          data: scaleBy ? scaledPerformance : performance,
          enableMouseTracking: enableMouseTracking !== undefined ? enableMouseTracking : true,
        },
      ],
    }

    if (!disableMarker) {
      highChartsConfig.plotOptions.area.marker = HIGHCHARTS_CONFIG.marker
    }

    return (
      <div className="HistoryChart">
        <ReactHighcharts ref="chart" config={highChartsConfig}></ReactHighcharts>
        {xAxisFirstLabel ? <div className="xAxis-fixed-label">{xAxisFirstLabel}</div> : null}
        {xAxisLastLabel ? <div className="xAxis-fixed-label right">{xAxisLastLabel}</div> : null}
      </div>
    )
  }
}

export default HistoryChart

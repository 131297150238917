/* @flow */
'use strict'

import React, { Component } from 'react'
import FastClick from 'fastclick'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'

import { formatValue } from '../../../core/helpers'

import variables from '../../../stylesheets/utils/variables.scss'

type Props = {
  id?: string,
  className?: string,
  errorText?: string,
  hintBottom?: string,
  hintBottomColor?: string,
  labelFixed?: string,
  linkPost?: string,
  linkPostText?: string,
  placeholderFixed?: string,
  prefix?: string,
  showFormattedValue?: boolean,
  // NOTE: add further sizes here if required
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
  style?: Object,
  type?: string,
  value?: ?number | string,
  onChange: (event: Object, value: number | string) => void,
  onSubmit?: () => void,
  onBlur?: () => void,
  onFocus?: () => void,
}

type State = {
  showFixedPlaceholder: boolean,
}

class Input extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      showFixedPlaceholder: props.value ? true : false,
    }
  }

  componentDidMount() {
    if (this.props.linkPost) {
      FastClick.attach(document.getElementById('subLink'))
    }
  }

  render() {
    const {
      id,
      className,
      floatingLabelText,
      errorText,
      hintText,
      hintBottom,
      hintBottomColor,
      linkPost,
      linkPostText,
      labelFixed,
      placeholderFixed,
      prefix,
      showFormattedValue,
      size,
      style,
      value,
      onChange,
      onSubmit,
      onFocus,
      onBlur,
      ...other
    } = this.props

    const showFixedPlaceholder = this.state.showFixedPlaceholder

    const defaultStyles = { fontFamily: 'OpenSansRegular' }
    const sizeStyles =
      size === 'xxs'
        ? { width: 55 }
        : size === 'xs'
        ? { width: 100 }
        : size === 'sm'
        ? { width: 150 }
        : size === 'md'
        ? { width: 200 }
        : size === 'lg'
        ? { width: 220 }
        : size === 'xl'
        ? { width: 280 }
        : size === 'xxl'
        ? { width: 350 }
        : null

    const inputStyles = { ...defaultStyles, ...sizeStyles, ...style }
    const inputClassName = `MuiInput ${prefix ? 'inputAfterPrefix' : 'inputWithoutPrefix'}`

    return (
      <div className={className ? `Input text-align-left ${className}` : 'Input text-align-left'}>
        {labelFixed && <div className="labelFixed sm">{labelFixed}</div>}
        {placeholderFixed && showFixedPlaceholder ? <div className="placeholderFixed">{placeholderFixed}</div> : null}
        {prefix && <div className="prefix sm">{prefix}</div>}

        <TextField
          id={id}
          className={inputClassName}
          autoCapitalize="off"
          autoCorrect="off"
          label={floatingLabelText}
          placeholder={hintText}
          maxLength={showFormattedValue ? 11 : 50}
          min={0}
          spellCheck="false"
          style={inputStyles}
          value={showFormattedValue && typeof value === 'number' ? formatValue(value) : value}
          onBlur={() => {
            if (placeholderFixed && !value) {
              this.setState({ showFixedPlaceholder: false })
            }
            if (!!onBlur) {
              onBlur()
            }
          }}
          onChange={(event: Object) => {
            const value = event.target.value
            if (showFormattedValue) {
              // only allow numeric values
              const numericValue = Number(value.replace(/\D/g, ''))
              // only allow positive values
              const currentValue = numericValue >= 0 ? numericValue : Math.abs(numericValue)
              onChange(event, currentValue)
            } else {
              onChange(event, value)
            }
          }}
          onFocus={() => {
            if (placeholderFixed) {
              this.setState({ showFixedPlaceholder: true })
            }
            if (!!onFocus) {
              onFocus()
            }
          }}
          onKeyDown={(event: Object) => {
            if (event.keyCode === 13 && onSubmit) {
              onSubmit()
            }
          }}
          {...other}
        />

        {linkPost && linkPostText && (
          <Link to={linkPost} id="subLink" className="inputLink">
            {linkPostText}
          </Link>
        )}

        {hintBottom && (
          <div className="hintBottom xs" style={{ color: hintBottomColor }}>
            {hintBottom}
          </div>
        )}
      </div>
    )
  }
}

export default Input

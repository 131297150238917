import request from 'core/request'
import { API_URL } from 'core/api'

function get(msgID: number): void {
  return request(`/message/show?msgid=${msgID}`)
}

function list(userID: ?number): void {
  const url = userID ? `/message/list?benutzerId=${userID}` : `/message/list`
  return request(url)
}

// function downloadAttachment(msgID: number, attachmentID: number): Promise {
//   const link = document.createElement('a')
//   link.href = `${API_URL}/message/attachment?msgid=${msgID}&attachmentid=${attachmentID}`
//   // Content-Disposition Header is used for filename
//   link.setAttribute('download', '')
//   document.body.appendChild(link)
//   link.click()
// }

const MessageService = {
  get,
  list,
  // downloadAttachment,
}

export default MessageService

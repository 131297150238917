/* @flow */
'use strict'

import React, { useEffect } from 'react'

import History from '../../ChartWrappers/History'
import Forecast from '../../ChartWrappers/Forecast'
import CardContainer from '../../UI/CardContainer'

import type { Partner } from '../../../reducers/main'
import type { Performance, Risiko, SavingsPlan as SavingsPlanType } from '../../../reducers/user'

type Props = {
  currencySign: string,
  currentValue: number,
  deposit: number,
  performance: Array<Performance>,
  rendite: number,
  risiko: Risiko,
  savingsPlan: SavingsPlanType,
  typ: Object,
  produktmantel: number,
  setShowIndex: (number) => void,
  isBAVProduct: boolean,
  resources: Object,
}

function ProductCard({
  currencySign,
  currentValue,
  deposit,
  performance,
  rendite,
  risiko,
  savingsPlan,
  typ,
  resources,
  setShowIndex,
  isBAVProduct,
}) {
  const subMenuItems = [
    { id: 'history', title: resources.history.title },
    { id: 'forecast', title: resources.forecast.title },
  ]

  useEffect(() => {
    setShowIndex(subMenuItems[0].id)
  }, [])

  return (
    <CardContainer subMenuItems={subMenuItems} className="ProductCard" setShowIndex={setShowIndex}>
      <div className="cardSlide">
        {!!performance && (
          <History currencySign={currencySign} performance={performance} resources={resources.history} />
        )}
      </div>

      <div className="cardSlide">
        <Forecast
          currencySign={currencySign}
          currentValue={currentValue}
          deposit={deposit}
          rendite={rendite}
          risiko={risiko}
          savingsPlan={savingsPlan}
          typ={typ}
          isBAVProduct={isBAVProduct}
          resources={resources.forecast}
        />
      </div>
    </CardContainer>
  )
}

export default ProductCard

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.ProductCard {\n  opacity: 1;\n  -webkit-transition: opacity 0.5s ease-in-out;\n  -moz-transition: opacity 0.5s ease-in-out;\n  -ms-transition: opacity 0.5s ease-in-out;\n  -o-transition: opacity 0.5s ease-in-out; }\n  .ProductCard h5 {\n    font-size: 0.7rem;\n    color: #8d9eaa; }\n  .ProductCard .highlight {\n    color: #0E3F60; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

import { fetch as fetchPolyfill } from 'whatwg-fetch'
import fetchDefaults from 'fetch-defaults'
import config from 'config/config'

const API_URL = 'api'
const AUTH_TOKEN = btoa(`finabro:asdf`)
// TODO: add environment vars in jenkins
// const AUTH_TOKEN = btoa(`${HTTP_USERNAME}:${HTTP_PASSWORD}`)

export const domain = PROD ? config.prod_server : DEV ? config.dev_server : TEST ? config.test_server : location.origin
export const baseURL = `${domain}/${API_URL}`

window.fetch = fetch = fetchPolyfill

const client = fetchDefaults(fetchPolyfill, {
  headers: { Authorization: `Basic ${AUTH_TOKEN}` },
})

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const onSuccess = (response) => {
    if (response.headers.get('content-type')?.includes('application/json')) {
      return response.json()
    }

    if (response.headers.get('content-type')?.includes('application/pdf')) {
      return response.blob()
    }

    return response.text()
  }

  const onError = function (error) {
    console.error('Request Failed:', error.config)

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status)
      console.error('Data:', error.response.data)
      console.error('Headers:', error.response.headers)
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message)
    }

    return Promise.reject(error.response || error.message)
  }

  const url = typeof options === 'object' ? options.url : options

  return client(`${baseURL}${url}`, {
    method: options.method,
    body: options.data,
    headers: options.method === 'POST' ? { 'Content-Type': 'application/json' } : {},
  })
    .then(onSuccess)
    .catch(onError)
}

export default request

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import defaultResource from 'resources/default.json'

import '../../stylesheets/utils/helpers.scss'
import variables from '../../stylesheets/utils/variables.scss'

const muiTheme = createMuiTheme({
  palette: {
    primary1Color: variables.primaryColor,
    accent1Color: variables.secondaryColor,
  },
})

type State = {
  resources?: Object,
}

function General({ children }) {
  // const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (window.StatusBar) {
      StatusBar.backgroundColorByHexString('#ffffff')
    }

    // return () => {}
  }, [])

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className="General view">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>

        <div className="content">
          {/* NOTE: pass props to child route component */}
          {children && React.cloneElement(children, { resources: defaultResource.general })}
        </div>
      </div>
    </MuiThemeProvider>
  )
}

export default General

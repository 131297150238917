import * as React from 'react'

function SvgIconMore(props) {
  return (
    <svg viewBox="0 0 1024 1025" {...props}>
      <path d="M512 434a72 72 0 11-72 72 72.08 72.08 0 0172-72m0-24a96 96 0 1096 96 96 96 0 00-96-96z" />
      <path d="M512 434a72 72 0 11-72 72 72.08 72.08 0 0172-72m0-24a96 96 0 1096 96 96 96 0 00-96-96zM800 434a72 72 0 11-72 72 72.08 72.08 0 0172-72m0-24a96 96 0 1096 96 96 96 0 00-96-96z" />
      <path d="M800 434a72 72 0 11-72 72 72.08 72.08 0 0172-72m0-24a96 96 0 1096 96 96 96 0 00-96-96zM224 434a72 72 0 11-72 72 72.08 72.08 0 0172-72m0-24a96 96 0 1096 96 96 96 0 00-96-96z" />
      <path d="M224 434a72 72 0 11-72 72 72.08 72.08 0 0172-72m0-24a96 96 0 1096 96 96 96 0 00-96-96z" />
    </svg>
  )
}

export default SvgIconMore

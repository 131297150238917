// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n/*******************\n * JAVASCRIPT EXPORT\n *******************/\n\n.InformationTile {\n  width: 80%;\n  margin: 1rem auto;\n  border: 1px solid #c4c4c4;\n  border-radius: 6px; }\n  .InformationTile__icon {\n    margin-right: 0.6rem;\n    width: 50px;\n    height: 50px;\n    max-width: 50px;\n    max-height: 50px; }\n    .InformationTile__icon > img {\n      max-width: 50px;\n      max-height: 50px; }\n    .InformationTile__icon > svg {\n      width: 40px;\n      height: 40px;\n      max-width: 40px;\n      max-height: 40px; }\n  .InformationTile__content {\n    margin: 1rem 10%; }\n  .InformationTile .label {\n    font-size: 0.9rem;\n    color: #0e3f60; }\n", ""]);
// Exports
exports.locals = {
	"primary": "#0e3f60",
	"secondary": "#32d69a",
	"danger": "#ea1818",
	"chartLineColor1": "#32d69a",
	"chartLineColor2": "#959595",
	"chartLineColor3": "#0e3f60",
	"chartGridColor": "#f9f9f9",
	"portfolioDefaultColor": "#959595",
	"portfolioSharesColor": "#32d69a",
	"portfolioBondsColor": "#0e3f60",
	"portfolioCashColor": "#757b7f",
	"portfolioOtherColor": "#3c3b3b",
	"defaultFontSize": "16px"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.subMenu {\n  display: flex;\n  height: 50px; }\n  .subMenu > div {\n    color: #8d9eaa;\n    font-size: 0.8rem;\n    padding-top: 10px;\n    line-height: 35px;\n    border-bottom: 1px solid #f5f9fc;\n    text-align: center; }\n    .subMenu > div.selected {\n      color: #0E3F60;\n      border-bottom: 3px solid #32d69a;\n      font-family: OpenSansSemibold, \"Helvetica Neue\", Helvetica, sans-serif; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.EmployerCard .EmployerTable {\n  width: 100%;\n  table-layout: fixed;\n  font-size: 0.8rem;\n  text-align: left;\n  border-collapse: collapse; }\n  .EmployerCard .EmployerTable thead tr, .EmployerCard .EmployerTable tbody tr {\n    height: 45px; }\n    .EmployerCard .EmployerTable thead tr:nth-child(even), .EmployerCard .EmployerTable tbody tr:nth-child(even) {\n      background-color: #fffde3; }\n    .EmployerCard .EmployerTable thead tr th, .EmployerCard .EmployerTable tbody tr th {\n      color: #8d9eaa;\n      border-bottom: 1px solid #f5f9fc; }\n    .EmployerCard .EmployerTable thead tr th, .EmployerCard .EmployerTable thead tr td, .EmployerCard .EmployerTable tbody tr th, .EmployerCard .EmployerTable tbody tr td {\n      word-wrap: break-word;\n      padding: 0 10px;\n      box-sizing: border-box;\n      font-weight: normal; }\n      .EmployerCard .EmployerTable thead tr th:last-child, .EmployerCard .EmployerTable thead tr td:last-child, .EmployerCard .EmployerTable tbody tr th:last-child, .EmployerCard .EmployerTable tbody tr td:last-child {\n        text-align: right; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

/* @flow */
'use strict'

import type { Action } from '../actions/types'
import type { User } from './user'

type State = {
  data: ?User,
  clients: Array<User>,
  filteredStatus: ?number,
}

const initialState: State = {
  data: null,
  clients: [],
  filteredStatus: null,
}

export default function adminReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_INITIAL_ADMIN':
      return fetchInitialAdmin(state, action)

    default:
      return state
  }
}

function fetchInitialAdmin(state, action): State {
  const { adminData, clients } = action.payload

  return {
    ...state,
    data: adminData,
    clients: clients,
  }
}

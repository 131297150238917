/* @flow */
'use strict'

import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { IconTrend, IconShare, IconTransactions, IconBriefcase, IconMore } from 'components/UI/SVG'
import { isProductDepot } from 'utils/matrix'

type Props = {
  resources: Object,
  index: number,
  signupType: number,
  produktmantel: number,
  partnerid: number,
  setShowIndex: (number) => void,
}

const IconComponent = ({ type, ...props }) => {
  switch (type) {
    case 'product':
      return <IconTrend {...props} />

    case 'positions':
      return <IconShare {...props} />

    case 'transactions':
      return <IconTransactions {...props} />

    case 'employer':
      return <IconBriefcase {...props} />

    case 'details':
    default:
      return <IconMore {...props} />
  }
}

function CardFooter({ resources, index, produktmantel, setShowIndex }) {
  const location = useLocation()
  const history = useHistory()

  return (
    <div className="CardFooter">
      {resources.items.map((item, ix) => {
        if (item.type === 'transactions' && !isProductDepot(produktmantel)) {
          return null
        } else {
          return (
            <div
              key={ix}
              className={`CardFooter__item ${
                (location.pathname.indexOf(item.type) >= 0 && location.pathname.indexOf('data') < 0) ||
                (item.type === 'details' && location.pathname.indexOf('data') >= 0)
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                history.push(`/inside/${index}/${item.type}`)
              }}
            >
              <IconComponent className="CardFooter__itemIcon" type={item.type} />
              <span className="CardFooter__itemText">{item.title}</span>
            </div>
          )
        }
      })}
    </div>
  )
}

export default CardFooter

'use strict'

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Document, Page, pdfjs } from 'react-pdf'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { loadPdf } from 'core/api'

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.js'

type Props = {
  resources?: Object,
}

type State = {
  pdfURL?: string,
  pages?: number,
}

class PDFPage extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      pdfURL: null,
      pages: 0,
    }

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.onDocumentLoadError = this.onDocumentLoadError.bind(this)
  }

  componentDidMount() {
    const { pdfURL, type } = this.props.match.params
    const url = '/' + decodeURIComponent(pdfURL)

    loadPdf(url, type, ({ pdfURL }) => this.setState({ pdfURL }))
  }

  onDocumentLoadSuccess(pages: number): void {
    this.setState({ pages })
  }

  onDocumentLoadError(error): void {
    console.log('Error while loading document! ' + error.message)
  }

  render() {
    const { pdfURL, pages } = this.state

    let rows = []
    for (let i = 1; i <= pages.numPages; i++) {
      rows.push(<Page key={i} pageNumber={i} width={window.innerWidth} />)
    }

    // TODO: load PDF worker from file system
    // const searchIndex = location.href.indexOf('+++') >= 0 ? location.href.indexOf('+++') : location.href.indexOf('www')

    // const options = {
    //   workerSrc: !LOCAL
    //     ? location.href.substr(0, searchIndex + 3) + '/lib/pdf.worker.min.js'
    //     : './lib/pdf.worker.min.js',
    // }

    return (
      <div className="PDFPage container infoBox">
        <div className="column bubble">
          <div className="heading">
            <h2>{decodeURIComponent(this.props.match.params.subject)}</h2>
          </div>

          <TransformWrapper className="items">
            <TransformComponent>
              {pdfURL && (
                <Document
                  file={pdfURL}
                  // options={options}
                  loading="Anhang wird geladen..."
                  error="Dokument nicht gefunden"
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadError={this.onDocumentLoadError}
                >
                  {rows}
                </Document>
              )}
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>
    )
  }
}

export default withRouter(PDFPage)

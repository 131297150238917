// GENERATED FILE, SOURCE: environment/src/config/config.finabro.js on 2022-05-24T11:50:53.582Z

module.exports = {
  themeId: 'finabro',
  mandantId: '418e6f70-2225-4f7e-a2d9-4815f9763909',
  mandantKey: 'finabro',
  prod_server: 'https://inside.finabro.at',
  dev_server: 'https://app-dev.finabro.com',
  test_server: 'https://t-app.finabro.at',
  title: 'FINABRO',
}

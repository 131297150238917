// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.HeaderBar {\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  height: 40px;\n  width: 100%;\n  z-index: 5; }\n  .HeaderBar .left,\n  .HeaderBar .right,\n  .HeaderBar .center {\n    flex: 1; }\n  .HeaderBar .left .backArrow {\n    display: inline-block;\n    position: relative;\n    padding: 0px 10px;\n    height: 40px;\n    width: 25px; }\n  .HeaderBar .logo {\n    height: 20px;\n    margin: 10px 0; }\n  .HeaderBar .center {\n    display: inline-block; }\n  .HeaderBar .right {\n    display: flex;\n    justify-content: flex-end; }\n  .HeaderBar .HeaderBar__item--messages {\n    position: relative; }\n  .HeaderBar .HeaderBar__itemIndicator {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    font-size: 0.5rem;\n    background: #32d69a;\n    color: #0E3F60;\n    right: 7px;\n    top: 6px;\n    border-radius: 100%;\n    width: 12px;\n    height: 12px; }\n  .HeaderBar .statusName {\n    color: #0E3F60;\n    font-size: 0.8rem;\n    line-height: 40px;\n    height: 40px;\n    margin-top: 2px; }\n    .HeaderBar .statusName:after {\n      content: '';\n      position: absolute;\n      top: -10px;\n      bottom: -10px;\n      left: 80px;\n      right: 80px; }\n  .HeaderBar .userDown {\n    display: inline-block;\n    height: 40px;\n    margin-left: 5%; }\n    .HeaderBar .userDown img {\n      height: 10px; }\n  .HeaderBar .icon {\n    display: inline-block;\n    position: relative;\n    height: 40px;\n    width: 25px;\n    padding: 0px 10px; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**************\n *   COLORS   *\n **************/\n/*************\n *   FONTS   *\n *************/\n/*********************************\n * BREAKPOINTS FOR MEDIA QUERIES *\n *********************************/\n\n.PositionsCard .positionsWrapper {\n  width: 95%;\n  padding: 2.5%;\n  font-size: 12px;\n  color: #8d9eaa; }\n  .PositionsCard .positionsWrapper > div {\n    background-color: #F0F4F7;\n    min-height: 90px;\n    padding-bottom: 20px; }\n    .PositionsCard .positionsWrapper > div:not(:last-child) {\n      margin-bottom: 20px; }\n\n.PositionsCard .flex-container {\n  grid-area: cells;\n  display: flex;\n  flex-wrap: wrap; }\n\n.PositionsCard .position-link {\n  width: 25px; }\n  .PositionsCard .position-link a {\n    position: relative;\n    z-index: 99; }\n\n.PositionsCard .position-header {\n  width: calc(100% - 35px);\n  padding: 10px 0px 12px 10px;\n  color: #0E3F60;\n  font-size: 13px; }\n  .PositionsCard .position-header > div {\n    width: 100%;\n    overflow: hidden;\n    text-overflow: ellipsis; }\n  .PositionsCard .position-header > div:nth-child(1) {\n    font-weight: bold; }\n  .PositionsCard .position-header > div:nth-child(2) {\n    white-space: normal; }\n\n.PositionsCard .row-value {\n  width: 25%;\n  text-align: left;\n  font-weight: bold;\n  color: #0E3F60; }\n\n.PositionsCard .row-label {\n  width: calc(25% - 10px);\n  text-align: left;\n  padding-left: 10px; }\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#0E3F60",
	"secondaryColor": "#32d69a",
	"chartColor": "#32d69a",
	"chartLineColor": "#8d9eaa",
	"chartLineColor2": "#1769a1",
	"chartLineColor3": "#32d69a",
	"chartGridColor": "#f6f6f6",
	"green": "#32d69a",
	"danger": "#EA1818",
	"darkgray": "#757b7f",
	"backgroundColor": "#e4ecf9"
};
module.exports = exports;
